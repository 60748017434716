
export default function ChartNumbers({reverse}) {
	return (
		<ul className="dental-chart__charts_numbers" style={{flexDirection: reverse ? "row-reverse" : ""}}>
			<li className="dental-chart__charts_numbers__item">1</li>
			<li className="dental-chart__charts_numbers__item">2</li>
			<li className="dental-chart__charts_numbers__item">3</li>
			<li className="dental-chart__charts_numbers__item">4</li>
			<li className="dental-chart__charts_numbers__item">5</li>
			<li className="dental-chart__charts_numbers__item">6</li>
			<li className="dental-chart__charts_numbers__item">7</li>
			<li className="dental-chart__charts_numbers__item">8</li>
		</ul>
	);
}
