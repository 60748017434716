export default [
    {
        "value": "0",
        "label": "شنبه"
    },
    {
        "value": "1",
        "label": "یکشنبه"
    },
    {
        "value": "2",
        "label": "دوشنبه"
    },
    {
        "value": "3",
        "label": "سه شنبه"
    },
    {
        "value": "4",
        "label": "چهارشنبه"
    },
    {
        "value": "5",
        "label": "پنج شنبه"
    },
    {
        "value": "6",
        "label": "جمعه"
    }
]