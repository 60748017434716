import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { AiFillDelete } from "react-icons/ai";
import Button from "@mui/material/Button";
import translate from "../../functions/translate";
import loader from "../../assets/loader.svg";
import { useSelector } from "react-redux";
import { original, serialize } from "../../functions/helper";
import swal from "sweetalert";

import API from "../../api/baseUrl";

export default function DocumentTable({
	data,
	loading,
	serviceCodeDelete,
	deteleItem,
	handleClickRow,
	keySelect,
	handleUpdate,
	disabledHead,
}) {
	const Login_User_Token = localStorage.getItem("user_login_token");
	const Login_User_cp = localStorage.getItem("user_login_cp");

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [selected, setSelected] = React.useState({});

	const documentValues = useSelector((state) => state.documentValues);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const handleClick = (row) => {
		if (handleClickRow) {
			handleClickRow(row);
			setSelected(row);
		}
	};

	const handleDelete = (id) => {
		try {
			swal("", `آیا برای حذف چارت ${id} مطمعن هستید؟`, {
				button: "بله",
			}).then((value) => {
				if (value) {
					fetchDelete(id);
				}
			});
		} catch (error) {
			console.log(error);
			swal("", `${error}`, "warning");
		}
	};

	const fetchDelete = async (id) => {
		try {
			const response = await API.post(
				serialize({
					id: 4208,
					sp_param: `'${id}','${Login_User_cp}'`,
					Login_User_Token,
					Login_User_cp,
				}),
			);
			let resParse = original(response.data, false);
			console.log(resParse);
			handleUpdate();
			swal("", `${resParse[0].s_message}`, "success");
		} catch (error) {
			console.log(error);
			swal("", `${error}`, "warning");
		}
	};

	return (
		<Paper sx={{ width: "100%", overflow: "hidden" }}>
			<TableContainer sx={{ maxHeight: 380 }}>
				{!loading ? (
					<Table className="app-table" stickyHeader aria-label="sticky table">
						{!disabledHead ? (
							<TableHead>
								<TableRow>
									{Object.keys(data[0]).map((column, i) => (
										<TableCell
											key={i}
											align={"center"}
											style={{ minWidth: column.minWidth }}
										>
											{translate(column)}
										</TableCell>
									))}
									{deteleItem && (
										<TableCell align={"center"}>
											{translate("delete")}
										</TableCell>
									)}
								</TableRow>
							</TableHead>
						) : (
							<></>
						)}
						<TableBody>
							{data
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row) => {
									return (
										<TableRow
											hover
											role="checkbox"
											tabIndex={-1}
											key={row.code}
											onClick={() => handleClick(row)}
											className={`${
												handleClickRow &&
												documentValues[keySelect] === row[keySelect]
													? "selected"
													: ""
											} ${handleClickRow && "app-table__tr-hover"}`}
										>
											{Object.entries(row).map(([key, val], i) => {
												if (key === "cs") return <></>;
												else {
													return (
														<TableCell key={i} align={"center"}>
															{val}
														</TableCell>
													);
												}
											})}
											{deteleItem && (
												<TableCell align={"center"}>
													<Button
														variant="text"
														className="app-table__delete-btn"
														onClick={() => handleDelete(row.id_dental_chart)}
													>
														<AiFillDelete />
													</Button>
												</TableCell>
											)}
										</TableRow>
									);
								})}
						</TableBody>
					</Table>
				) : (
					<div className="loader-container">
						<img src={loader} />
					</div>
				)}
			</TableContainer>
			{data.length > 10 ? (
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={data.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			) : (
				<></>
			)}
		</Paper>
	);
}
