import { useState } from "react";
import InputMask from "react-input-mask";

import { IoIosArrowDown } from "react-icons/io";

const dateMask = {
  mask: "9999/99/99",
  maskChar: "_",
  alwaysShowMask: false,
  formatChars: {
    9: "[0-9]",
    a: "[A-Za-z]",
    "*": "[A-Za-z0-9]",
  },
  permanents: [2, 5], // permanents is an array of indexes of the non-editable characters in the mask
};

const timeMask = {
  mask: "99:99",
  maskChar: "_",
  alwaysShowMask: false,
  formatChars: {
    9: "[0-9]",
    // a: "[A-Za-z]",
    "*": "[A-Za-z0-9]",
  },
};

export default function ReportFilter({ handleFilter }) {
  // const Login_User_Token = localStorage.getItem("user_login_token");
  const Login_User_cp = localStorage.getItem("user_login_cp");

  const [filter, setFilter] = useState({
    fdate: "", //char(10),
    tdate: "", // char(10),
    ftime: "", // datetime,
    ttime: "", // datetime,
    cp: Login_User_cp, // int,
    cp_expert: "", // int,
    cs: "", // int,
    c_pay: "", // int,
    c_bnk: "", // int,
    c_insura: "", // int,
    c_insura_sub: "", // int,
    ins_cp: "", // int,
    edi_cp: "", // int,
    del_cp: "", // int,
    descr: "", // nvarchar(50),
    cc: "", // int,--  بخش
    ccs: "", // int,-- زیر بخش
    cp_technician: "", // int,  --تکنسین
    c_kind: "", // int,  --  نوع عمومی تخصصی
    c_shift: "", // int,--  شیفت
    c_branch: "", // int,-- شعبه
    cp_str: "", // varchar(500),  --  چند شخص
    cp_expert_str: "", // varchar(500),  --  چند پزشک
    cs_str: "", // varchar(500),  ---  چند سرویس
    c_insura_str: "", // varchar(500), -- چند بیمه
    cc_str: "", // varchar(500), --- چند بخش
    cp_technician_str: "", // varchar(500),  -- چند تکنسین
    c_shift_str: "", // varchar(500), -- چند شیفت
    c_branch_str: "", // varchar(500),  --  چند شعبه
    cp_den_lab: "", // int,
    lucp: Login_User_cp, // int
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    handleFilter(filter);
  };

  return (
    <div class="accordion">
      <div class="accordion__tabs">
        <div class="accordion__tab">
          <input type="checkbox" id="chck1" class="accordion__tab_input" />
          <label class="accordion__tab_label" for="chck1">
            <IoIosArrowDown />
            <span>فیلتر کردن</span>
          </label>
          <div class="accordion__tab_content report-filter">
            <form className="report-filter__form">
              <div className="text-input">
                <label className="text-input__label">تاریخ</label>
                <InputMask
                  {...dateMask}
                  className="text-input__input ltr"
                  onChange={(e) => {
                    setFilter({ ...filter, fdate: e.target.value });
                  }}
                />
              </div>
              <div className="text-input">
                <label className="text-input__label">تا تاریخ</label>
                <InputMask
                  {...dateMask}
                  className="text-input__input ltr"
                  onChange={(e) => {
                    setFilter({ ...filter, tdate: e.target.value });
                  }}
                />
              </div>
              <div className="text-input">
                <label className="text-input__label">از ساعت</label>
                <InputMask
                  {...timeMask}
                  className="text-input__input ltr"
                  onChange={(e) => {
                    setFilter({ ...filter, ftime: e.target.value });
                  }}
                />
              </div>
              <div className="text-input">
                <label className="text-input__label">تا ساعت</label>
                <InputMask
                  {...timeMask}
                  className="text-input__input ltr"
                  onChange={(e) => {
                    setFilter({ ...filter, ttime: e.target.value });
                  }}
                />
              </div>
              <div className="button-container" onClick={handleSubmit}>
                <button className="button">اعمال فیلتر</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
