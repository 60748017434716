import { useState } from "react";
import API from "../../api/baseUrl";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import Captcha from "demos-react-captcha";

import { serialize, original } from "../../functions/helper";
import config from "../../config.json";
import { enDate } from "../../functions/date";
import CaptchaNumeric from "react-numeric-captcha";

import "../../assets/sass/authStyle.scss";
import Loading from "../../components/loading";

export default function Signup() {
	const [loading, setLoading] = useState(false);

	const history = useHistory();

	const [data, setdata] = useState({
		initialCode: "",
		phone: "",
	});
	const [captcha, setCaptcha] = useState(false);

	const handleSignup = async (e) => {
		e.preventDefault();
		const button = e.target;
		button.setAttribute("disabled", "disabled");

		try {
			if (config.show_signup_captcha === 1) {
				if (!captcha && config.show_signup_captcha === 1) {
					swal("", `لطفا متن تصویر را درست وارد کنید`, "warning", {
						button: "متوجه شدم",
					});
					button.removeAttribute("disabled");
					return;
				}
			}

			if (config.show_signup_captcha_numeric === 1) {
				if (!captcha && config.show_signup_captcha_numeric === 1) {
					swal("", `لطفا متن تصویر را درست وارد کنید`, "warning", {
						button: "متوجه شدم",
					});
					button.removeAttribute("disabled");
					return;
				}
			}

			const loginData = await API.post(
				serialize({
					id: 1112,
					username: 0,
					password: 0,
				}),
			);
			const { user_login_token, user_login_cp } = original(loginData.data);

			const res = await API.post(
				serialize({
					id: config.signup_api_id ? config.signup_api_id : 1702,
					sp_param: `'${data.initialCode}','${data.phone}','${user_login_cp}'`,
					Login_User_Token: user_login_token,
					Login_User_cp: user_login_cp,
				}),
			);
			console.log(original(res.data));
			let { s_message, s_result, __COLUMN1 } = original(res.data);
			if (s_result > "0") {
				// swal("", `${s_message}`, "succsess");
				setLoading(true);
				setTimeout(() => {
					handleLogin();
				}, 3000);
				button.removeAttribute("disabled");
			} else if (s_result === "0") {
				swal("", `${s_message}`, "warning");
				button.removeAttribute("disabled");
			} else if (__COLUMN1 === "0") {
				setLoading(true);
				setTimeout(() => {
					handleLogin();
				}, 3000);
				button.removeAttribute("disabled");
			} else {
				swal("", `${"خطایی رخ داد لطفا دوباره امتحان کنید"}`, "warning");
				button.removeAttribute("disabled");
			}
		} catch (error) {
			// swal("", `${error}`, "warning");
			console.log(error);
			button.removeAttribute("disabled");
		}
	};

	const handleLogin = async (e) => {
		try {
			const response = await API.post(
				serialize({
					id: 1112,
					username: data.initialCode,
					password: data.phone,
				}),
			);

			let {
				user_login_cp,
				user_login_message,
				user_login_token,
				user_login_np,
			} = original(response.data);

			if (user_login_cp !== "0") {
				localStorage.setItem("user_login_cp", user_login_cp);
				localStorage.setItem("user_login_token", user_login_token);
				localStorage.setItem("user_login_np", user_login_np);
				history.push("/panel");
				swal(
					"",
					`شما پیشفرض با
          نام کاربری: کد ملی
          رمزعبور: شماره تلفن
          وارد شده اید لطفا برای تغییر نام کاربری و رمز عبور اقدام نمایید`,
					"success",
					{
						button: "متوجه شدم",
					},
				);
			} else {
				swal("", `خطایی رخ داده`, "warning");
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.log(err.response);
			swal("", `${err}`, "warning", { button: "بسیار خب" });
		}
	};

	const onChangeCaptcha = (value) => {
		if (value) {
			setCaptcha(value);
		} else {
			setCaptcha(false);
		}
	};

	// 1710254289

	return (
		<div
			className="signup-page"
			style={{
				background: `linear-gradient(45deg,${
					config.auth_back_color_1
						? config.auth_back_color_1
						: "rgba(86, 0, 110, 1)"
				} 10%,${
					config.auth_back_color_2
						? config.auth_back_color_2
						: "rgba(33, 150, 243, 1)"
				} 100%)`,
			}}
		>
			<div className="signup-page__box">
				<h1>{config.header}</h1>
				<h2 className="signup-page__title">{config.header_signup}</h2>
				<form className="signup-page__form">
					<div className="text-input">
						{/* <label for="username" className="text-input__label">نام کاربری</label> */}
						<input
							type="text"
							id="username"
							className="text-input__input ltr"
							onChange={(e) =>
								setdata({ ...data, initialCode: e.target.value })
							}
							placeholder={
								config.register_input_initial_code
									? config.register_input_initial_code
									: "initial code"
							}
						/>
					</div>

					<div className="text-input">
						{/* <label for="username" className="text-input__label">کلمه عبور</label> */}
						<input
							type="text"
							id="password"
							className="text-input__input ltr"
							onChange={(e) => setdata({ ...data, phone: e.target.value })}
							placeholder={
								config.register_input_phone
									? config.register_input_phone
									: "phone"
							}
						/>
					</div>

					{config.show_signup_captcha === 1 && (
						<Captcha onChange={onChangeCaptcha} placeholder="کد تصویر" />
					)}

					{config.show_signup_captcha_numeric === 1 && (
						<CaptchaNumeric onChange={onChangeCaptcha} placeholder="کد تصویر" />
					)}

					<button className="signup-page__form_btn" onClick={handleSignup}>
						{config.register_button_text
							? config.register_button_text
							: "signup"}
					</button>
					<div className="signup-page__form_links">
						<Link to="/">ورود</Link>
					</div>
				</form>
				<div className="signup-page__box_footer">
					<span>{enDate}</span>
				</div>
			</div>
			{loading && <Loading />}
		</div>
	);
}
