import { useEffect, useState } from "react";
import swal from "sweetalert";

import API from "../../../api/baseUrl";
import { serialize, original } from "../../../functions/helper";
import loader from "../../../assets/loader.svg";
import translate from "../../../functions/translate";

export default function Logs2() {
  const Login_User_Token = localStorage.getItem("user_login_token");
  const Login_User_cp = localStorage.getItem("user_login_cp");

  // const [data, setData] = useState({
  //   id: 1588,
  //   sp_param: `'${Login_User_cp}'`,
  //   Login_User_Token,
  //   Login_User_cp,
  // });

  const [reportList, setReportList] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    API.post(
      serialize({
        id: 1607,
        sp_param: `'${Login_User_cp}'`,
        Login_User_Token,
        Login_User_cp,
      })
    )
      .then((response) => {
        let res = original(response.data, false);
        setReportList(res);
        setLoading(false);
        // console.log(res);
      })
      .catch((err) => {
        // this.setState({connectingStatus: false})
        swal("", `${err}`, "warning");
      });

    // try {
    //   const res = await API.post("", null, {
    //     params: {
    //       id: 1588,
    //       sp_param: "1",
    //       Login_User_Token,
    //       Login_User_cp,
    //     },
    //   });
    //   setData(res.data.Data);
    //   setLoading(false);
    //   console.log(res);
    // } catch (error) {
    //   console.log(error);
    // }
  };

  return (
    <div className="overflow">
      {!loading ? (
        <table className="table">
          {reportList[0] && (
            <thead>
              <tr>
                {Object.keys(reportList[0]).map((item, i) => (
                  <th key={i}>{translate(item)}</th>
                ))}
              </tr>
            </thead>
          )}

          <tbody>
            {reportList.length > 0 ? (
              reportList.map((item, index) => (
                <tr key={index}>
                  {Object.values(item).map((val, i) => (
                    <td key={i}>{val}</td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td>آیتمی برای نمایش وجود ندارد</td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <div className="loader-container">
          <img src={loader} />
        </div>
      )}
    </div>
  );
}
