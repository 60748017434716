import { useEffect, useState } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import { useDispatch, connect } from "react-redux";

import API from "../../api/baseUrl";
import { serialize, original } from "../../functions/helper";
import Header from "../../components/Header";
import Side from "../../components/Side";
import Dashboard from "./dashboard/Dashboard";
import Profile from "./profile/Profile";
import Report from "./report/Report";
import Visit from "./visit/Visit";
import config from "../../config.json";
import loader from "../../assets/loader.svg";
import Deposit from "./deposit/Deposit";
import Check from "./check/Check";
import Appointments from "./appointments/Appointments";
import DentalChart from "./dentalChart";
import DentalChartDesktop from "./dentalChart/desktop";
import HelpLink from "./helpLink/HelpLink";
import swal from "sweetalert";
import Documents from "./documents";
import Payment from "./payment";

function Panel(props) {
	const Login_User_cp = localStorage.getItem("user_login_cp");
	const Login_User_Token = localStorage.getItem("user_login_token");

	const dispatch = useDispatch();
	const history = useHistory();

	const [loading, setLoading] = useState(true);

	const { state } = props;

	useEffect(() => {
		checkPermition();
		handleSettings();

		// document.body.innerHTML = document.body.innerHTML.replace('ی', 'ي');
	}, []);

	const handleSettings = async () => {
		try {
			const [resPermission, resLan] = await Promise.all([
				API.post(
					serialize({
						id: 1001,
						sp_param: Login_User_cp,
						Login_User_Token,
						Login_User_cp,
					}),
				),
				API.post(
					serialize({
						id: 1512,
						sp_param: `'${config.translate}','${Login_User_cp}'`,
						Login_User_Token,
						Login_User_cp,
					}),
				),
			]);
			let resPermissionParse = original(resPermission.data, false);
			let resLanParse = original(resLan.data, false);

			let permissions = [];
			resPermissionParse.map((item) => {
				permissions.push(item.c_permission);
			});

			dispatch({
				type: "PERMISSION_LIST",
				payload: permissions,
			});
			dispatch({
				type: "TRANSLATE_LIST",
				payload: resLanParse,
			});

			setLoading(false);
			// console.log(resParse);
		} catch (error) {
			console.log(error);
		}
	};

	const checkPermition = async () => {
		// check alert show permition
		const resPer = await API.post(
			serialize({
				id: 1500,
				sp_param: ``,
				Login_User_Token,
				Login_User_cp,
			}),
		);
		if (resPer) {
			let resultPer = original(resPer.data, false);
			if (resultPer[0].act_alarm_after_login === "False") return;
		}

		const res = await API.post(
			serialize({
				id: 1589,
				sp_param: `'','','${Login_User_cp}'`,
				Login_User_Token,
				Login_User_cp,
			}),
		);
		if (!res) return;
		let result = original(res.data, false);

		if (result[0].exit_program_after_alarm === "True") {
			swal("", `${result[0].s_alarm}`, "warning");
			window.localStorage.clear();
			history.push("/");
		}
	};

	return (
		<div className="panel-page">
			<div className="panel-page__grid">
				<div className="panel-page__side">
					<Side />
				</div>
				<div className="panel-page__main">
					<Header />
					<div className="panel-page__pages">
						<Switch>
							{state.permissions.includes("1036") && (
								<Route path="/panel/dashboard" component={Dashboard} />
							)}
							{state.permissions.includes("1002") && (
								<Route path="/panel/profile" component={Profile} />
							)}
							{state.permissions.includes("1004") && (
								<Route path="/panel/visit" component={Visit} />
							)}
							{state.permissions.includes("1007") && (
								<Route path="/panel/report" component={Report} />
							)}
							{state.permissions.includes("1012") && (
								<Route path="/panel/deposit" component={Deposit} />
							)}
							{state.permissions.includes("1013") && (
								<Route path="/panel/check" component={Check} />
							)}
							{state.permissions.includes("1023") && (
								<Route path="/panel/appointments" component={Appointments} />
							)}
							{config.show_helpLinks && (
								<Route path="/panel/helplink" component={HelpLink} />
							)}
							{state.permissions.includes("1024") && (
								<Route path="/panel/dental-chart" component={DentalChart} />
							)}
							{state.permissions.includes("1024") && (
								<Route
									path="/panel/dental-chart-desktop"
									component={DentalChartDesktop}
								/>
							)}
							{state.permissions.includes("1052") && (
								<Route path="/panel/documents" component={Documents} />
							)}
							{config.show_payment_test && (
								<Route path="/panel/payment" component={Payment} />
							)}
							{/* <Redirect from="/panel" to="/panel/dashboard" /> */}
						</Switch>
					</div>
				</div>
			</div>

			<div
				className={`main-loading`}
				style={{ display: `${!loading ? "none" : "flex"}` }}
			>
				<img src={loader} />
			</div>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		state: state,
	};
};

export default connect(mapStateToProps)(Panel);
