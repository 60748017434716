import { Fragment, useEffect } from "react";
import { Link, Redirect, Route, Switch, useLocation } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import Reservation from "./Reservation";
import StatuList from "./StatusList";

import config from "../../../config.json";
import UserInfo from "./UserInfo";
import UserVisit from "./UserVisit";
import translate from "../../../functions/translate";
import Logs2 from "./Logs2";
import Logs3 from "./Logs3";
import DocumentProfile from "./Documents";

export default function Profile() {
	const location = useLocation();

	useEffect(() => {
		handleActiveMenu();
	}, [location.pathname]);

	const handleActiveMenu = () => {
		[...document.querySelectorAll(".profile-page__nav_item")].map((item) => {
			item.classList.remove("profile-page__nav_item-active");
		});
		[...document.querySelectorAll(".profile-page__nav_item")].map((item) => {
			if (
				item.firstChild.getAttribute("href") === location.pathname &&
				item.firstChild
					.getAttribute("href")
					.indexOf(location.pathname.split("/")[2])
			) {
				item.classList.add("profile-page__nav_item-active");
			}
		});
	};

	return (
		<Fragment>
			<div className="panel-head">
				<h2 className="panel-head_title">پروفایل</h2>
			</div>
			<div className="profile-page panel-page__paper">
				<div className="profile-page__nav">
					<ul className="profile-page__nav_items">
						{config.profile_tab_user_info ? (
							<li className="profile-page__nav_item profile-page__nav_item-active">
								<Link to="/panel/profile/info">{translate("userinfo")}</Link>
							</li>
						) : (
							<></>
						)}
						{config.profile_tab_user_password ? (
							<li className="profile-page__nav_item">
								<Link to="/panel/profile/password">
									{translate("password")}
								</Link>
							</li>
						) : (
							<></>
						)}
						{config.profile_tab_appointments ? (
							<li className="profile-page__nav_item">
								<Link to="/panel/profile/visit">
									{translate("appointments")}
								</Link>
							</li>
						) : (
							<></>
						)}
						{config.profile_tab_reserve ? (
							<li className="profile-page__nav_item">
								<Link to="/panel/profile/reservation">
									{translate("reservation")}
								</Link>
							</li>
						) : (
							<></>
						)}
						{config.profile_tab_logs ? (
							<li className="profile-page__nav_item">
								<Link to="/panel/profile/logs">{translate("logs")}</Link>
							</li>
						) : (
							<></>
						)}
						{config.profile_tab_logs2 ? (
							<li className="profile-page__nav_item">
								<Link to="/panel/profile/logs2">{translate("logs2")}</Link>
							</li>
						) : (
							<></>
						)}
						{config.profile_tab_logs3 ? (
							<li className="profile-page__nav_item">
								<Link to="/panel/profile/logs3">{translate("logs3")}</Link>
							</li>
						) : (
							<></>
						)}
						{/* {config.profile_tab_documents ? (
							<li className="profile-page__nav_item">
								<Link to="/panel/profile/documents">
									{translate("document")}
								</Link>
							</li>
						) : (
							<></>
						)} */}
					</ul>
				</div>
				<div className="profile-page__panel">
					<Switch>
						{config.profile_tab_user_info && (
							<Route path="/panel/profile/info" component={UserInfo} />
						)}
						{config.profile_tab_user_password && (
							<Route
								path="/panel/profile/password"
								component={ChangePassword}
							/>
						)}
						{config.profile_tab_appointments && (
							<Route path="/panel/profile/visit" component={UserVisit} />
						)}
						{config.profile_tab_reserve && (
							<Route
								path="/panel/profile/reservation"
								component={Reservation}
							/>
						)}
						{config.profile_tab_logs && (
							<Route path="/panel/profile/logs" component={StatuList} />
						)}
						{config.profile_tab_logs2 && (
							<Route path="/panel/profile/logs2" component={Logs2} />
						)}
						{config.profile_tab_logs3 && (
							<Route path="/panel/profile/logs3" component={Logs3} />
						)}
						{/* {config.profile_tab_documents && (
							<Route
								path="/panel/profile/documents"
								component={DocumentProfile}
							/>
						)} */}
						<Redirect from="/panel/profile" to="/panel/profile/info" />
					</Switch>
				</div>
			</div>
		</Fragment>
	);
}
