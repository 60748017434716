import { Fragment, useEffect, useState } from "react";

import API from "../../../api/baseUrl";
import { serialize, original } from "../../../functions/helper";
import loader from "../../../assets/loader.svg";
import { paymentService } from "../../../api/payment";

export default function Info() {
  const Login_User_Token = localStorage.getItem("user_login_token");
  const Login_User_cp = localStorage.getItem("user_login_cp");

  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await API.post(
        serialize({
          id: 3101,
          sp_param: `'${Login_User_cp}'`,
          Login_User_Token,
          Login_User_cp,
        })
      );
      let resParse = original(res.data, false);

      setDataList(resParse);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <div className="panel-head">
        <h2 className="panel-head__title">داشبورد</h2>
        
      </div>
      <div className="dashboard-page panel-page__paper">
        <div className="overflow">
          <table
            className="table dashboard-table"
            style={{ width: "max-content" }}
          >
            <tbody>
              {!loading ? (
                dataList.map((item, index) => (
                  <tr key={index}>
                    {Object.values(item).map((val, i) => (
                      <td key={i}>{val}</td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td>
                    <div className="loader-container">
                      <img src={loader} />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
}
