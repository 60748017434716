import React, { Fragment, useState, useEffect } from "react";
import DocumentTable from "../../../../components/documentTable";
import translate from "../../../../functions/translate";
import API from "../../../../api/baseUrl";
import { serialize, original } from "../../../../functions/helper";
import { useDispatch, useSelector } from "react-redux";

export default function DentalStepOne() {
	const Login_User_Token = localStorage.getItem("user_login_token");
	const Login_User_cp = localStorage.getItem("user_login_cp");

	const documentValues = useSelector(state => state.documentValues)

	const dispatch = useDispatch();

	const [personTableList, setPersonTableList] = useState([]);
	const [dataLoading, setDataLoading] = useState(true);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const response = await API.post(
				serialize({
					id: 4233,
					sp_param: `'${documentValues.cp}','${Login_User_cp}'`,
					Login_User_Token,
					Login_User_cp,
				}),
			);

			let resParse = original(response.data, false);

			console.log(resParse);
			setPersonTableList(resParse);
			setDataLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	const handleSetValue = (values) => {
		dispatch({
			type: "UPDATE_DOCUMENTS_VALUES",
			payload: {
				...values
			},
		});
	};



	return (
		<Fragment>
			<DocumentTable
				data={personTableList}
				loading={dataLoading}
				deteleItem={false}
				serviceCodeDelete={4208}
				keySelect="ref_row"
				handleUpdate={fetchData}
				disabledHead={true}
				handleClickRow={handleSetValue}
			/>
		</Fragment>
	);
}
