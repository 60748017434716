import {Fragment} from 'react';
import config from "../../../config.json";

import "./style.scss";


export default function HelpLink() {
    return (
        <Fragment>
            <div className="panel-head">
                <h2 className="panel-head__title">لینک کمکی</h2>
            </div>

            <div className="helplink-page panel-page__paper">
                <ul className="helplinks">
                    {
                        config.helpLinks.map((item, index) => (
                            <li key={index}>
                                <a href={item.link}>{item.title}</a>
                            </li>
                        ))
                    }
                </ul>
            </div>

        </Fragment>
    )
}
