import store from "../store";

export default function translate(string) {
  const translateList = store.getState().translate;

  let newString = "";
  for (const value of translateList) {
    if (value.txt === string) {
      newString = value.t_txt;
      break;
    } else {
      newString = string;
    }
  }
  return newString;
}
