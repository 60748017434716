const initialState = [];

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case "PERMISSION_LIST":
        return [ ...payload ]

    default:
        return state
    }
}
