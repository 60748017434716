import React, { Fragment, useState, useEffect } from "react";
import Table from "../../../../components/table";
import Select from "react-select";
import translate from "../../../../functions/translate";
import API from "../../../../api/baseUrl";
import { serialize, original } from "../../../../functions/helper";
import swal from "sweetalert";
import { useDispatch } from "react-redux";

export default function DentalStepOne() {
	const Login_User_Token = localStorage.getItem("user_login_token");
	const Login_User_cp = localStorage.getItem("user_login_cp");

	const dispatch = useDispatch();

	const [personList, setPersonList] = useState([]);
	const [userLoading, setUserLoading] = useState(false);
	const [personTableList, setPersonTableList] = useState([]);
	const [dataLoading, setDataLoading] = useState(true);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const response = await API.post(
				serialize({
					id: 4201,
					sp_param: `'${Login_User_cp}'`,
					Login_User_Token,
					Login_User_cp,
				}),
			);

			let resParse = original(response.data, false);
			setPersonTableList(resParse);
			setDataLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	// const handleUserInputChange = (e) => {
	// 	if (e === "") {
	// 		setPersonList([]);
	// 		setUserLoading(false);
	// 		return;
	// 	}
	// 	setUserLoading(true);

	// 	API.post(
	// 		serialize({
	// 			id: 2467,
	// 			sp_param: `'${e}','20','${Login_User_cp}'`,
	// 			Login_User_Token,
	// 			Login_User_cp,
	// 		}),
	// 	)
	// 		.then((response) => {
	// 			let res = original(response.data, false);
	// 			let personArr = [];
	// 			res.map((item) => {
	// 				personArr.push({
	// 					label: `${item.np} (${item.cp})`,
	// 					value: item.cp,
	// 				});
	// 			});
	// 			// console.log(res);
	// 			setPersonList(personArr);
	// 			setUserLoading(false);
	// 		})
	// 		.catch((err) => {
	// 			// this.setState({connectingStatus: false})
	// 			swal("", `${err}`, "warning");
	// 			setUserLoading(false);
	// 		});
	// };

	// // select user on select box
	// const handleSelectUser = (e) => {
	// 	if (e) {
	// 		handleSetInsura(e.value, e.label);
	// 	}
	// 	else setValues({ ...values, cp: "", np: "" });
	// };

	// //set insura data
	// const handleSetInsura = async (cp, np) => {
	// 	try {
	// 		const response = await API.post(
	// 			serialize({
	// 				id: 2775,
	// 				sp_param: `${cp},'${Login_User_cp}'`,
	// 				Login_User_Token,
	// 				Login_User_cp,
	// 			}),
	// 		);
	// 		let res = original(response.data, false);

	// 		setValues({
	// 			...values,
	// 			cp,
	// 			np,
	// 			n_insura: res[0].n_insura,
	// 			c_insura: res[0].c_insura,
	// 			n_insura_sub: res[0].n_insura_sub,
	// 			c_insura_sub: res[0].c_insura_sub,
	// 		});

	//         dispatch({
	//             type: "UPDATE_CHRT_VALUES",
	//             payload: {
	//                 cp,
	//                 np,

	//             }
	//         })

	// 	} catch (error) {
	// 		console.log(error);
	// 	}
	// };

	return (
		<Fragment>
			{/* <div className="dental-step-form">
				<div className="text-input">
					<label className="text-input__label">{translate("cp")}</label>
					<Select
						options={personList}
						isLoading={userLoading}
						onChange={(e) => handleSelectUser(e)}
						onInputChange={(e) => handleUserInputChange(e)}
						loadingMessage={() => "لطفا صبر کنید"}
						// onKeyDown={handleFindUser}
						placeholder=""
						isClearable={true}
						styles={{
							option: (base) => ({
								...base,
								border: `1px dotted red`,
								height: "100%",
								zIndex: 70000,
							}),
							zIndex: 70000,
						}}
					/>
				</div>
			</div> */}
			<Table
				data={personTableList}
				loading={dataLoading}
				deteleItem={true}
				serviceCodeDelete={4208}
				keySelect="cp"
				handleUpdate={fetchData}
			/>
		</Fragment>
	);
}
