import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Panel from "./pages/panel/Panel";
import Login from "./pages/auth/Login";
import PrivateRoute from "./components/PrivetRoute";
import Signup from "./pages/auth/Signup";
import config from "./config.json";
import FastVisit from "./pages/auth/fastVisit";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Login} />
        {config.disabled_register_page !== 1 && (
          <Route exact path="/signup" component={Signup} />
        )}
        {config.disabled_fast_visit_page !== 1 && (
          <Route exact path="/visit" component={FastVisit} />
        )}
        <PrivateRoute path="/panel" component={Panel} />
      </Switch>
    </Router>
  );
}

export default App;
