import React, { useState, useEffect } from "react";
import ChartOne from "../../../../components/charts/ChartOne";
import { original, serialize } from "../../../../functions/helper";
import API from "../../../../api/baseUrl";
import ChartTow from "../../../../components/charts/ChartTow";
import ChartThree from "../../../../components/charts/ChartThree";
import ChartFhor from "../../../../components/charts/ChratFhor";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { faDate } from "../../../../functions/date";

export default function DentalStepFhor() {
	const Login_User_Token = localStorage.getItem("user_login_token");
	const Login_User_cp = localStorage.getItem("user_login_cp");

	const [chart, setChart] = useState([]);
	const [dentalList, setDentalList] = useState([]);

	const dispatch = useDispatch();

	const chartValues = useSelector(state => state.chartValues);

	useEffect(() => {
		setSelectChartData();
	}, []);

	//set data on dental chart
	const setSelectChartData = async () => {
		try {
			// if (!values.cp) {
			// 	swal("", "لطفا یک کاربر را انتخاب کنید", { button: "متوجه شدم" });
			// 	return;
			// }
			const response = await API.post(
				serialize({
					id: 2784,
					sp_param: `'${faDate}','${chartValues.cp}','${Login_User_cp}'`,
					Login_User_Token,
					Login_User_cp,
				}),
			);
			let res = original(response.data, false);
			console.log(res);
			setChart(res);
		} catch (error) {
			console.log(error);
		}
	};

	// set dental item to list
	const handleSetDentalList = (dental) => {
		setDentalList([...dentalList, dental]);
		dispatch({
			type: "UPDATE_CHRT_VALUES",
			payload: {
				LNUM: [...dentalList, dental],
			},
		});
	};

	// remove dental item to list
	const handleRemoveDentalList = (dental) => {
		setDentalList(dentalList.filter((item) => item !== dental));
	};

	return (
		<div className="dantal-step-fhor">
			<div className="charts">
				<div className="charts__row charts__row-1">
					<div className="charts__col charts__one">
						<ChartOne
							chart={chart}
							handleSetDentalList={handleSetDentalList}
							handleRemoveDentalList={handleRemoveDentalList}
							list={dentalList}
						/>
					</div>
					<div className="charts__col charts__tow">
						<ChartTow
							chart={chart}
							handleSetDentalList={handleSetDentalList}
							handleRemoveDentalList={handleRemoveDentalList}
							list={dentalList}
						/>
					</div>
				</div>

				<div className="charts__row charts__row-2">
					<div className="charts__col charts__three">
						<ChartThree
							chart={chart}
							handleSetDentalList={handleSetDentalList}
							handleRemoveDentalList={handleRemoveDentalList}
							list={dentalList}
						/>
					</div>
					<div className="charts__col charts__fhor">
						<ChartFhor
							chart={chart}
							handleSetDentalList={handleSetDentalList}
							handleRemoveDentalList={handleRemoveDentalList}
							list={dentalList}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
