import { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { BiUser, BiHelpCircle } from "react-icons/bi";
import { BsListUl } from "react-icons/bs";
import { FaRegChartBar, FaRegMoneyBillAlt, FaTooth } from "react-icons/fa";
import { GiHospitalCross } from "react-icons/gi";
import { FiLogOut } from "react-icons/fi";
import { RiDashboardFill, RiSecurePaymentFill } from "react-icons/ri";
import { AiOutlineDoubleRight, AiOutlineDoubleLeft } from "react-icons/ai";
import { TiDocumentText } from "react-icons/ti";
import { connect } from "react-redux";
import logo from "../assets/img/logo.png";
import logoFull from "../assets/img/logofull.png";
import translate from "../functions/translate";
import queryString from "query-string";

import config from "../config.json";

function Side(props) {
	const location = useLocation();
	// const history = useHistory();
	const query = queryString.parse(location.search);

	const [toggleNav, setToggleNav] = useState(true);

	const { state } = props;

	useEffect(() => {
		handleActiveMenu();
		window.onclick = (e) => toggleNavMobile(e);
	}, [location.pathname, state.permissions]);

	const handleActiveMenu = () => {
		[...document.querySelectorAll(".side__item")].map((item) => {
			item.classList.remove("side__item-active");
		});
		[...document.querySelectorAll(".side__item")].map((item) => {
			if (
				(item.firstChild.getAttribute("href") === location.pathname &&
					item.firstChild
						.getAttribute("href")
						.indexOf(location.pathname.split("/")[2])) ||
				item.firstChild.getAttribute("href").split("/")[2] ===
					location.pathname.split("/")[2]
			) {
				item.classList.add("side__item-active");
			}
		});
		if (window.innerWidth < 768) {
			document.getElementById("sideNav").classList.add("side__mobile");
			setToggleNav(true);
		}
	};

	const handleToggoleNav = () => {
		document.getElementById("sideNav").classList.toggle("side__close");
		setToggleNav(!toggleNav);
	};

	const toggleNavMobile = (e) => {
		if (
			!e.target.closest(".side__mobile") &&
			!e.target.closest(".header__toggle-menu") &&
			document.getElementById("sideNav")
		) {
			// console.log(e.target);
			document.getElementById("sideNav").classList.remove("mobile-show");
		}
	};

	return (
		<aside
			className="side"
			id="sideNav"
			style={{ display: query.fullWidth ? "none" : "" }}
		>
			<div className="side__logo">
				{toggleNav ? (
					<img src={logoFull} className="side__logo_full" />
				) : (
					<img src={logo} className="side__logo_min" />
				)}
			</div>
			<div className="overflow" style={{ overflowX: "hidden" }}>
				<ul className="side__items">
					{state.permissions.includes("1036") && (
						<li className="side__item">
							<Link to="/panel/dashboard">
								<RiDashboardFill />
								<span>داشبورد</span>
							</Link>
						</li>
					)}

					{state.permissions.includes("1004") && (
						<li className="side__item">
							<Link to="/panel/visit">
								<GiHospitalCross />
								<span>نوبت دهی</span>
							</Link>
						</li>
					)}

					{state.permissions.includes("1007") && (
						<li className="side__item">
							<Link to="/panel/report">
								<FaRegChartBar />
								<span>گزارش ها</span>
							</Link>
						</li>
					)}

					{state.permissions.includes("1012") && (
						<li className="side__item">
							<Link to="/panel/deposit">
								<RiSecurePaymentFill />
								<span>واریزی</span>
							</Link>
						</li>
					)}

					{state.permissions.includes("1013") && (
						<li className="side__item">
							<Link to="/panel/check">
								<FaRegMoneyBillAlt />
								<span>چک</span>
							</Link>
						</li>
					)}

					{state.permissions.includes("1002") && (
						<li className="side__item">
							<Link to="/panel/profile">
								<BiUser />
								<span>پروفایل</span>
							</Link>
						</li>
					)}

					{state.permissions.includes("1023") && (
						<li className="side__item">
							<Link to="/panel/appointments">
								<BsListUl />
								<span>همه وقت ها</span>
							</Link>
						</li>
					)}

					{state.permissions.includes("1024") && (
						<li className="side__item">
							<Link to="/panel/dental-chart">
								<FaTooth />
								<span>چارت دندانی</span>
							</Link>
						</li>
					)}

					{state.permissions.includes("1024") && (
						<li className="side__item">
							<Link to="/panel/dental-chart-desktop">
								<FaTooth />
								<span> چارت دندانی </span>
							</Link>
						</li>
					)}

					{config.show_helpLinks ? (
						<li className="side__item">
							<Link to="/panel/helplink">
								<BiHelpCircle />
								<span>{translate("help-link")}</span>
							</Link>
						</li>
					) : (
						<></>
					)}

					{state.permissions.includes("1052") && (
						<li className="side__item">
							<Link to="/panel/documents">
								<TiDocumentText />
								<span> مستندات </span>
							</Link>
						</li>
					)}

					{config.show_payment_test ? (
						<li className="side__item">
							<Link to="/panel/payment">
								<TiDocumentText />
								<span> پرداخت انلاین </span>
							</Link>
						</li>
					) : (
						<></>
					)}
				</ul>
			</div>

			<ul className="side__btns">
				<li>
					<button className="side__btns_toggle-nav" onClick={handleToggoleNav}>
						{toggleNav ? <AiOutlineDoubleRight /> : <AiOutlineDoubleLeft />}

						{/* <span>بستن</span> */}
					</button>
				</li>
			</ul>
		</aside>
	);
}

const mapStateToProps = (state) => {
	return {
		state: state,
	};
};

export default connect(mapStateToProps)(Side);
