import React, { useState, useEffect } from "react";
import { original, serialize } from "../../../../functions/helper";
import API_IMAGE from "../../../../api/showImageService";
import { useSelector } from "react-redux";

import config from "../../../../config.json";
import loader from "../../../../assets/loader.svg";

import axios from "axios";

export default function DentalStepFhor() {
	const Login_User_Token = localStorage.getItem("user_login_token");
	const Login_User_cp = localStorage.getItem("user_login_cp");

	const [image, setImage] = useState("");
	const [loading, setLoading] = useState(true);

	const documentValues = useSelector((state) => state.documentValues);

	useEffect(() => {
		setSelectChartData();
	}, []);

	const setSelectChartData = async () => {
		// try {
		// 	// if (!values.cp) {
		// 	// 	swal("", "لطفا یک کاربر را انتخاب کنید", { button: "متوجه شدم" });
		// 	// 	return;
		// 	// }
		// 	const response = await API_IMAGE.post(
		// 		serialize({
		// 			id: 1,
		// 			cp: documentValues.cp,
		// 			id_images: documentValues.id_images,
		// 			token: Login_User_Token,
		// 			lucp: Login_User_cp,
		// 		}),
		// 	);
		// 	let res = original(response.data, false);

		// 	console.log(res);
		// } catch (error) {
		// 	console.log(error);
		// }

		var configReq = {
			method: "post",
			url: `${config.url}:${config.show_image_port}?id=1&cp=${documentValues.cp}&id_images=${documentValues.id_images}&token=${Login_User_Token}&lucp=${Login_User_cp}`,
			headers: {},
		};

		axios(configReq)
			.then(function (response) {
				setImage(response.data);
				setLoading(false);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<div className="dantal-step-fhor">
			{!loading ? (
				<img
					src={`data:image/jpage;base64,${image}`}
					style={{ maxWidth: "100%" }}
				/>
			) : (
				<div className="loader-container">
					<img src={loader} />
				</div>
			)}
		</div>
	);
}
