import React from "react";
import config from "./../config.json";
import swal from "sweetalert";

export const serialize = (data) => {
	let queryString = [];
	if (config.response_type === 0) {
		Object.entries(data).forEach(([key, value]) => {
			if (key === "id") queryString.push(`${key}=${value}`);
			else queryString.push(`&${key}=${value}`);
		});
	} else {
		Object.entries(data).forEach(([key, value]) => {
			queryString.push(`${key}=${value}`);
		});
	}

	console.log(queryString);

	return config.response_type === 1
		? "?" +
				btoa(
					encodeURIComponent(queryString.join(String.fromCharCode(13))).replace(
						/%([0-9A-F]{2})/g,
						function (match, p1) {
							return String.fromCharCode(parseInt(p1, 16));
						},
					),
				)
		: "?" + queryString.join("");

	//	 return config.response_type === 1
	//   ? "?" +
	//       btoa(
	//         encodeURIComponent(queryString.join(String.fromCharCode(13))).replace(
	//           /%([0-9A-F]{2})/g,
	//           function (match, p1) {
	//             return String.fromCharCode(parseInt(p1, 16));
	//           }
	//         )
	//       )
	//   : "?" +
	//       queryString
	//         .join(String.fromCharCode(13))
	//         .replace(/%([0-9A-F]{2})/g, function (match, p1) {
	//           return String.fromCharCode(parseInt(p1, 16));
	//         });
};

export const validator = (data) => {
	let status = true;

	Object.entries(data).forEach(([key, value]) => {
		if (!value.toString().length) {
			status = false;
		}
	});

	return status;
};

export const original = (data, flag = true) => {
	if (config.response_type === 1) {
		// console.log(JSON.parse(atob(data)));
		if (flag) return JSON.parse(atob(data)).Data[0];
		else return JSON.parse(atob(data)).Data;
	} else {
		if (flag) return data.Data[0];
		else return data.Data;
	}
};

export const authCheck = (data) => {
	if (data) {
		if (
			data.hasOwnProperty("s_result") &&
			parseInt(data.s_result) <= 0 &&
			data.s_result === "-1111"
		) {
			return false;
		}
	}

	return true;
};

export const sResultCheck = (data) => {
	if (data) {
		if (data.length === 1) {
			if (
				data[0].hasOwnProperty("s_result") &&
				parseInt(data[0].s_result) <= 0
			) {
				swal.fire({
					icon: "error",
					text: data[0].s_message,
					confirmButtonText: "متوجه شدم",
				});
				return false;
			}
		}
	}

	return true;
};

export const showFlash = (key) => {
	if (
		window.localStorage.hasOwnProperty(key) &&
		window.localStorage.getItem(key).length > 0
	) {
		swal.fire({
			icon: "info",
			title: "پیام سیستم",
			text: window.localStorage.getItem(key),
			confirmButtonText: "متوجه شدم",
		});
		window.localStorage.removeItem(key);
	}
};

export const nullable = (data) => {
	return data !== " " ? (
		<span>{data}</span>
	) : (
		<em style={{ color: "rgba(0,0,0,.3)" }} className="small">
			NULL
		</em>
	);
};
