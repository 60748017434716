import { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";

import API from "../../../api/baseUrl";
import { serialize, original } from "../../../functions/helper";
import ModalReport from "../../../components/modal/ModalReport";
import ReportFilter from "../../../components/acordion/ReportFilter";
import loader from "../../../assets/loader.svg";

function Report(props) {
  const Login_User_Token = localStorage.getItem("user_login_token");
  const Login_User_cp = localStorage.getItem("user_login_cp");
  const { state } = props;

  const [reportList, setReportList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [reportData, setReportData] = useState(null);

  const [filter, setFilter] = useState({
    fdate: "", //char(10),
    tdate: "", // char(10),
    ftime: "", // datetime,
    ttime: "", // datetime,
    cp: "", // int,
    cp_expert: "", // int,
    cs: "", // int,
    c_pay: "", // int,
    c_bnk: "", // int,
    c_insura: "", // int,
    c_insura_sub: "", // int,
    ins_cp: "", // int,
    edi_cp: "", // int,
    del_cp: "", // int,
    descr: "", // nvarchar(50),
    cc: "", // int,--  بخش
    ccs: "", // int,-- زیر بخش
    cp_technician: "", // int,  --تکنسین
    c_kind: "", // int,  --  نوع عمومی تخصصی
    c_shift: "", // int,--  شیفت
    c_branch: "", // int,-- شعبه
    cp_str: "", // varchar(500),  --  چند شخص
    cp_expert_str: "", // varchar(500),  --  چند پزشک
    cs_str: "", // varchar(500),  ---  چند سرویس
    c_insura_str: "", // varchar(500), -- چند بیمه
    cc_str: "", // varchar(500), --- چند بخش
    cp_technician_str: "", // varchar(500),  -- چند تکنسین
    c_shift_str: "", // varchar(500), -- چند شیفت
    c_branch_str: "", // varchar(500),  --  چند شعبه
    cp_den_lab: "", // int,
    c_special: "", //int
    lucp: Login_User_cp, // int
  });

  useEffect(() => {
    fetchReportList();
  }, []);

  const fetchReportList = async () => {
    try {
      const res = await API.post(
        serialize({
          id: 2464,
          sp_param: `${Login_User_cp}`,
          Login_User_Token,
          Login_User_cp,
        })
      );
      let resParse = original(res.data, false);
      setReportList(resParse);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowModal = (data) => {
    console.log(data);
    setReportData(data);
    setShowModal(true);
  };

  const handleFilter = (event) => {
    console.log(event);
    setFilter(event);
  };

  return (
    <Fragment>
      <div className="panel-head">
        <h2 className="panel-head_title">گزارش ها</h2>
      </div>
      <div className="report-page panel-page__paper">

        {state.permissions.includes("1037") && (
          <ReportFilter handleFilter={handleFilter} />
        )}
        <div className="overflow">
          <table className="table table-report">
            {/* <thead>
          <tr></tr>
        </thead> */}

            <tbody>
              {!loading ? (
                reportList.length > 0 ? (
                  reportList.map((item) => (
                    <tr
                      key={item.c_permission}
                      onClick={() => handleShowModal(item)}
                    >
                      <td>{item.c_permission}</td>
                      <td>{item.report_operation}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>آیتمی برای نمایش وجود ندارد</td>
                  </tr>
                )
              ) : (
                <div className="loader-container">
                  <img src={loader} />
                </div>
              )}
            </tbody>
          </table>
        </div>

        <ModalReport
          showModal={showModal}
          closeModal={() => setShowModal(false)}
          data={reportData}
          filter={filter}
        />
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    state: state,
  };
};

export default connect(mapStateToProps)(Report);
