import { useEffect, useState } from "react";
import API from "../../../api/baseUrl";
import { serialize, original } from "../../../functions/helper";
// import swal from "sweetalert";

import loader from "../../../assets/loader.svg";
import translate from "../../../functions/translate";

export default function VisitList({ update }) {
  const Login_User_Token = localStorage.getItem("user_login_token");
  const Login_User_cp = localStorage.getItem("user_login_cp");

  const [visitListState, setVisitListState] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [update]);

  const fetchData = async () => {
    try {
      const res = await API.post(
        serialize({
          id: 2349,
          sp_param: `'','','${Login_User_cp}'`,
          Login_User_Token,
          Login_User_cp,
        })
      );
      let resParse = original(res.data, false);

      setVisitListState(resParse);
      setLoading(false);
    } catch (error) {
      // swal("", `${error}`, "warning");
      console.log(error);
    }
  };

  // console.log(visitListState);

  return (
    <div className="visit-list panel-page__paper_list">
      <div className="visit-list__title panel-page__paper_list__title">
        <h3>نوبت های ثبت شده اخیر</h3>
      </div>
      <div className="overflow">
        {!loading ? (
          <table className="table" style={{ width: "max-content" }}>
            {visitListState[0] && (
              <thead>
                <tr>
                  {Object.keys(visitListState[0]).map((item, i) => (
                    <th key={i}>{translate(item)}</th>
                  ))}
                </tr>
              </thead>
            )}
            <tbody>
              {visitListState.length > 0 ? (
                visitListState.map((item, index) => (
                  <tr key={index}>
                    {Object.values(item).map((val, i) => (
                      <td key={i}>{val}</td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td>آیتمی برای نمایش وجود ندارد</td>
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <div className="loader-container">
            <img src={loader} />
          </div>
        )}
      </div>
    </div>
  );
}
