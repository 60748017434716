import { Fragment, useEffect, useState } from "react";

import API from "../../../../api/baseUrl";
import { serialize, original } from "../../../../functions/helper";
import loader from "../../../../assets/loader.svg";
import Select from "react-select";
import swal from "sweetalert";
import translate from "../../../../functions/translate";
import DentalChartUr from "../../../../components/dentalChart/DentalChartUr";
import DentalChartUl from "../../../../components/dentalChart/DentalChartUl";
import DentalChartLr from "../../../../components/dentalChart/DentalChartLr";
import DentalChartLl from "../../../../components/dentalChart/DentalChartLl";
import { faDate } from "../../../../functions/date";
import ChartNumbers from "../../../../components/dentalChart/ChartNumbers";

export default function DentalChart() {
	const Login_User_Token = localStorage.getItem("user_login_token");
	const Login_User_cp = localStorage.getItem("user_login_cp");

	const [loading, setLoading] = useState(true);
	const [comboList, setComboList] = useState([]);
	const [personList, setPersonList] = useState([]);
	const [userLoading, setUserLoading] = useState(false);
	const [technicianList, setTechnicianList] = useState([]);
	const [departmentList, setDepartmentList] = useState([]);
	const [departmentSubsetList, setDepartmentSubsetList] = useState([]);
	const [specialist, setSpecialist] = useState([]);
	const [serviceTypeList, setServiceTypeList] = useState([]);
	const [chart, setChart] = useState([]);
	const [serviceList, setServiceList] = useState([]);
	const [dentalList, setDentalList] = useState([]);
	const [values, setValues] = useState({
		combo: { label: "", value: "" },
		technician: { label: "", value: "" },
		special: { label: "", value: "" },
		department: { label: "", value: "" },
		departmentSubset: { label: "", value: "" },
		serviceType: { label: "", value: "" },
		service: { label: "", value: "" },
		np: "",
		cp: "",
		n_insura: "",
		c_insura: "",
		n_insura_sub: "",
		c_insura_sub: "",
		ur: "0",
		ul: "0",
		lr: "0",
		ll: "0",
		upper: "0",
		lower: "0",
		all: "0",
		dentalButton: "",
		description: "",
		cl: "",
	});

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const [
				resCombo,
				resSpecialist,
				resTechnician,
				resDepartment,
				resDepartmentSubset,
				resServiceType,
				resService,
			] = await Promise.all([
				API.post(
					serialize({
						id: 1522,
						sp_param: "",
						Login_User_Token,
						Login_User_cp,
					}),
				),
				API.post(
					serialize({
						id: 1514,
						Login_User_Token,
						Login_User_cp,
						sp_param: `'${Login_User_cp}'`,
					}),
				),
				API.post(
					serialize({
						id: 1510,
						sp_param: `'','','','${Login_User_cp}'`,
						Login_User_Token,
						Login_User_cp,
					}),
				),
				API.post(
					serialize({
						id: 1502,
						Login_User_Token,
						Login_User_cp,
						sp_param: `'','','','${Login_User_cp}'`,
					}),
				),
				API.post(
					serialize({
						id: 1503,
						Login_User_Token,
						Login_User_cp,
						sp_param: `'','','','${Login_User_cp}'`,
					}),
				),
				API.post(
					serialize({
						id: 1521,
						Login_User_Token,
						Login_User_cp,
						sp_param: `'${Login_User_cp}'`,
					}),
				),
				API.post(
					serialize({
						id: 1506,
						sp_param: `'','','','${Login_User_cp}'`,
						Login_User_Token,
						Login_User_cp,
					}),
				),
			]);

			let resComboParse = original(resCombo.data, false);
			let resSpecialistParse = original(resSpecialist.data, false);
			let resTechnicianParse = original(resTechnician.data, false);
			let resDepartmentParse = original(resDepartment.data, false);
			let resDepartmentSubsetParse = original(resDepartmentSubset.data, false);
			let resServiceTypeParse = original(resServiceType.data, false);
			let resServiceParse = original(resService.data, false);

			let comboArr = [];
			let specialistArr = [];
			let technicianArr = [];
			let departmentArr = [];
			let departmentSubsetArr = [];
			let serviceTypeArr = [];
			let serviceArr = [];

			resComboParse.map((item) => {
				comboArr.push({
					label: `${item.n_combo}`,
					value: item.c_combo,
				});
			});

			resSpecialistParse.map((item) => {
				specialistArr.push({
					label: `${item.name_expert} (${item.cp})`,
					value: item.cp,
				});
			});

			resTechnicianParse.map((item) => {
				technicianArr.push({
					label: `${item.n_technician} (${item.cp})`,
					value: item.cp,
				});
			});

			resDepartmentParse.map((item) => {
				departmentArr.push({
					label: `${item.nc} (${item.cc})`,
					value: item.cc,
				});
			});

			resDepartmentSubsetParse.map((item) => {
				departmentSubsetArr.push({
					label: `${item.ncs} (${item.ccs})`,
					value: item.ccs,
				});
			});

			resServiceTypeParse.map((item) => {
				serviceTypeArr.push({
					label: `${item.n_combo} (${item.c_combo})`,
					value: item.c_combo,
				});
			});

			resServiceParse.map((item) => {
				serviceArr.push({ label: `${item.ns} (${item.cs})`, value: item.cs });
			});

			setComboList(comboArr);
			setSpecialist(specialistArr);
			setTechnicianList(technicianArr);
			setDepartmentList(departmentArr);
			setDepartmentSubsetList(departmentSubsetArr);
			setServiceTypeList(serviceTypeArr);
			setServiceList(serviceArr);

			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	// insert service
	let i = 0;
	function* handleInsertService() {
		// try {
		if (dentalList.length) {
			console.log(dentalList.length);
			while (i < dentalList.length) {
				console.log(dentalList[i]);
				swal({
					text: `${values.combo && values.combo.label}
								${values.np}
								${values.special && values.special.label}
								${values.technician && values.technician.label}
								${values.department && values.department.label}
								${values.departmentSubset && values.departmentSubset.label}
								${values.service && values.service.label}
								For ( ${dentalList[i]} )
								`,
					buttons: true,
				}).then((ok) => {
					if (ok) {
						fetchInsertServiceDentalNumbers(dentalList[i]).then((result) => {
							console.log(result);
							swal(`${result.s_message}`, {
								icon: "success",
							});
						});
						i += 1;
					}
				});
				yield i;
			}
		} else {
			swal({
				text: `${values.combo && values.combo.label}
						${values.np}
						${values.special && values.special.label}
						${values.technician && values.technician.label}
						${values.department && values.department.label}
						${values.departmentSubset && values.departmentSubset.label}
						${values.service && values.service.label}
						For ( ${values.dentalButton} )`,
				buttons: true,
			}).then((ok) => {
				if (ok) {
					fetchInsertServiceDentalButtons().then((result) => {
						console.log(result);
						swal(`${result.s_message}`, {
							icon: "success",
						});
					});
				}
			});
		}
	}

	const fetchInsertServiceDentalNumbers = async (item) => {
		let response = await API.post(
			serialize({
				id: 2781,
				sp_param: `'${faDate}','${values.cp}','${values.special.value}','${values.technician.value}','${values.combo.value}','${values.department.value}','${values.departmentSubset.value}','${values.serviceType.value}','${values.service.value}','${values.cl}','${item}','0','${values.all}','${values.upper}','${values.lower}','${values.ur}','${values.ul}','${values.lr}','${values.ll}','${values.description}','${Login_User_cp}'`,
				Login_User_Token,
				Login_User_cp,
			}),
		);
		let res = original(response.data, false);
		setTimeout(() => {
			let callInsert = handleInsertService();
			callInsert.next();
		}, 200);
		return res[0];
	};

	const fetchInsertServiceDentalButtons = async () => {
		let response = await API.post(
			serialize({
				id: 2781,
				sp_param: `'${faDate}','${values.cp}','${values.special.value}','${values.technician.value}','${values.combo.value}','${values.department.value}','${values.departmentSubset.value}','${values.serviceType.value}','${values.service.value}','${values.cl}','0','0','${values.all}','${values.upper}','${values.lower}','${values.ur}','${values.ul}','${values.lr}','${values.ll}','${values.description}','${Login_User_cp}'`,
				Login_User_Token,
				Login_User_cp,
			}),
		);
		let res = original(response.data, false);
		return res[0];
	};

	// find user service
	const handleUserInputChange = (e) => {
		if (e === "") {
			setPersonList([]);
			setUserLoading(false);
			return;
		}
		setUserLoading(true);

		API.post(
			serialize({
				id: 2467,
				sp_param: `'${e}','20','${Login_User_cp}'`,
				Login_User_Token,
				Login_User_cp,
			}),
		)
			.then((response) => {
				let res = original(response.data, false);
				let personArr = [];
				res.map((item) => {
					personArr.push({
						label: `${item.np} (${item.cp})`,
						value: item.cp,
					});
				});
				// console.log(res);
				setPersonList(personArr);
				setUserLoading(false);
			})
			.catch((err) => {
				// this.setState({connectingStatus: false})
				swal("", `${err}`, "warning");
				setUserLoading(false);
			});
	};

	// select user on select box
	const handleSelectUser = (e) => {
		if (e) {
			// setValues({ ...values,  });
			handleSetInsura(e.value, e.label);
		} else setValues({ ...values, cp: "", np: "" });
	};

	//set insura data
	const handleSetInsura = async (cp, np) => {
		try {
			const response = await API.post(
				serialize({
					id: 2775,
					sp_param: `${cp},'${Login_User_cp}'`,
					Login_User_Token,
					Login_User_cp,
				}),
			);
			let res = original(response.data, false);

			setValues({
				...values,
				cp,
				np,
				n_insura: res[0].n_insura,
				c_insura: res[0].c_insura,
				n_insura_sub: res[0].n_insura_sub,
				c_insura_sub: res[0].c_insura_sub,
			});
		} catch (error) {
			console.log(error);
		}
	};

	//set data on dental chart
	const setSelectChartData = async () => {
		try {
			if (!values.cp) {
				swal("", "لطفا یک کاربر را انتخاب کنید", { button: "متوجه شدم" });
				return;
			}
			const response = await API.post(
				serialize({
					id: 2784,
					sp_param: `'${faDate}','${values.cp}','${Login_User_cp}'`,
					Login_User_Token,
					Login_User_cp,
				}),
			);
			let res = original(response.data, false);
			console.log(res);
			setChart(res);
		} catch (error) {
			console.log(error);
		}
	};

	// set dental item to list
	const handleSetDentalList = (dental) => {
		setDentalList([...dentalList, dental]);
	};

	// remove dental item to list
	const handleRemoveDentalList = (dental) => {
		setDentalList(dentalList.filter((item) => item !== dental));
	};

	const callInsert = handleInsertService();

	return (
		<Fragment>
			<div className="panel-head">
				<h2 className="panel-head__title">چارت دندانی</h2>
			</div>
			<div className="dental-chart-page panel-page__paper">
				<div className="dental-chart__user-form">
					<div className="text-input">
						<label className="text-input__label">{translate("combo")}</label>
						<Select
							options={comboList}
							value={values.combo}
							isLoading={comboList.length === 0 ? true : false}
							onChange={(e) => {
								setValues({
									...values,
									combo: { label: e.label, value: e.value },
								});
								// setData({ ...data, cs: e.value });
							}}
							loadingMessage={() => "لطفا صبر کنید..."}
							placeholder=""
						/>
					</div>

					<div className="text-input">
						<label className="text-input__label">{translate("cp")}</label>
						<Select
							options={personList}
							isLoading={userLoading}
							onChange={(e) => handleSelectUser(e)}
							onInputChange={(e) => handleUserInputChange(e)}
							loadingMessage={() => "لطفا صبر کنید"}
							// onKeyDown={handleFindUser}
							placeholder=""
							isClearable={true}
						/>
					</div>

					<div className="text-input">
						<label className="text-input__label">{translate("np")}</label>
						<input className="text-input__input" value={values.np} readOnly />
					</div>

					<div className="text-input">
						<label className="text-input__label">{translate("insura")}</label>
						<input
							className="text-input__input"
							value={values.n_insura + " " + values.n_insura_sub}
							readOnly
							style={{ direction: "ltr", textAlign: "right" }}
						/>
					</div>

					<div style={{ width: "100%", textAlign: "left" }}>
						<button className="button" onClick={setSelectChartData}>
							{translate("add")}
						</button>
					</div>
				</div>

				<div className="">
					<div className="dental-chart__charts">
						<div className="dental-chart__charts_row1">
							<div className="dental-chart__charts_button">
								<button
									className={`dental-chart__charts_button__btn ${
										values.dentalButton === "ur" ? "active" : ""
									}`}
									onClick={() => {
										setValues({
											...values,
											ur: "1",
											ul: "0",
											lr: "0",
											ll: "0",
											all: "0",
											upper: "0",
											lower: "0",
											dentalButton: "ur",
										});
										setDentalList([]);
									}}
								>
									UR
								</button>
								<input className="dental-chart__charts_button_input" />
							</div>
							<div className="dental-chart__charts_button">
								<button
									className={`dental-chart__charts_button__btn ${
										values.dentalButton === "upper" ? "active" : ""
									}`}
									onClick={() => {
										setValues({
											...values,
											ur: "0",
											ul: "0",
											lr: "0",
											ll: "0",
											all: "0",
											upper: "1",
											lower: "0",
											dentalButton: "upper",
										});
										setDentalList([]);
									}}
								>
									UPPER
								</button>
							</div>
							<div className="dental-chart__charts_button">
								<input className="dental-chart__charts_button_input" />
								<button
									className={`dental-chart__charts_button__btn ${
										values.dentalButton === "ul" ? "active" : ""
									}`}
									onClick={() => {
										setValues({
											...values,
											ur: "0",
											ul: "1",
											lr: "0",
											ll: "0",
											all: "0",
											upper: "0",
											lower: "0",
											dentalButton: "ul",
										});
										setDentalList([]);
									}}
								>
									UL
								</button>
							</div>
						</div>
						<div className="dental-chart__charts_row2">
							<ChartNumbers reverse={true} />

							<DentalChartUr
								chart={chart}
								handleSetDentalList={handleSetDentalList}
								handleRemoveDentalList={handleRemoveDentalList}
								list={dentalList}
							/>
							<DentalChartUl
								chart={chart}
								handleSetDentalList={handleSetDentalList}
								handleRemoveDentalList={handleRemoveDentalList}
								list={dentalList}
							/>
							<ChartNumbers reverse={false} />
						</div>
						<div className="dental-chart__charts_row3">
							<div className="dental-chart__charts_row3__col-1">
								<ChartNumbers reverse={true} />
							</div>

							<div className="dental-chart__charts_row3__col-2">
								<button
									className={`dental-chart__charts_button__btn ${
										values.dentalButton === "all" ? "active" : ""
									}`}
									onClick={() => {
										setValues({
											...values,
											ur: "0",
											ul: "0",
											lr: "0",
											ll: "0",
											all: "1",
											upper: "0",
											lower: "0",
											dentalButton: "all",
										});
										setDentalList([]);
									}}
								>
									ALL
								</button>
							</div>

							<div className="dental-chart__charts_row3__col-3">
								<ChartNumbers reverse={false} />
							</div>
						</div>
						<div className="dental-chart__charts_row4">
							<ChartNumbers reverse={true} />

							<DentalChartLr
								chart={chart}
								handleSetDentalList={handleSetDentalList}
								handleRemoveDentalList={handleRemoveDentalList}
								list={dentalList}
							/>
							<DentalChartLl
								chart={chart}
								handleSetDentalList={handleSetDentalList}
								handleRemoveDentalList={handleRemoveDentalList}
								list={dentalList}
							/>
							<ChartNumbers reverse={false} />
						</div>
						<div className="dental-chart__charts_row5">
							<div className="dental-chart__charts_button">
								<button
									className={`dental-chart__charts_button__btn ${
										values.dentalButton === "lr" ? "active" : ""
									}`}
									onClick={() => {
										setValues({
											...values,
											ur: "0",
											ul: "0",
											lr: "1",
											ll: "0",
											all: "0",
											upper: "0",
											lower: "0",
											dentalButton: "lr",
										});
										setDentalList([]);
									}}
								>
									LR
								</button>
								<input className="dental-chart__charts_button_input" />
							</div>
							<div className="dental-chart__charts_button">
								<button
									className={`dental-chart__charts_button__btn ${
										values.dentalButton === "lower" ? "active" : ""
									}`}
									onClick={() => {
										setValues({
											...values,
											ur: "0",
											ul: "0",
											lr: "0",
											ll: "0",
											all: "0",
											upper: "0",
											lower: "1",
											dentalButton: "lower",
										});
										setDentalList([]);
									}}
								>
									LOWER
								</button>
							</div>
							<div className="dental-chart__charts_button">
								<input className="dental-chart__charts_button_input" />
								<button
									className={`dental-chart__charts_button__btn ${
										values.dentalButton === "ll" ? "active" : ""
									}`}
									onClick={() => {
										setValues({
											...values,
											ur: "0",
											ul: "0",
											lr: "0",
											ll: "1",
											all: "0",
											upper: "0",
											lower: "0",
											dentalButton: "ll",
										});
										setDentalList([]);
									}}
								>
									LL
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="dental-chart__options-form">
					<div className="text-input">
						<label className="text-input__label">
							{translate("cp_expert")}
						</label>
						<Select
							options={specialist}
							value={values.special}
							isLoading={specialist.length === 0 ? true : false}
							onChange={(e) => {
								setValues({
									...values,
									special: { label: e.label, value: e.value },
								});
							}}
							loadingMessage={() => "لطفا صبر کنید..."}
							placeholder=""
						/>
					</div>

					<div className="text-input">
						<label className="text-input__label">
							{translate("cp_tecnician")}
						</label>
						<Select
							options={technicianList}
							value={values.technician}
							isLoading={technicianList.length === 0 ? true : false}
							onChange={(e) => {
								setValues({
									...values,
									technician: { label: e.label, value: e.value },
								});
							}}
							loadingMessage={() => "لطفا صبر کنید..."}
							placeholder=""
						/>
					</div>

					<div className="text-input">
						<label className="text-input__label">{translate("cc")}</label>
						<Select
							options={departmentList}
							value={values.department}
							isLoading={departmentList.length === 0 ? true : false}
							onChange={(e) => {
								setValues({
									...values,
									department: { label: e.label, value: e.value },
								});
							}}
							loadingMessage={() => "لطفا صبر کنید..."}
							placeholder=""
						/>
					</div>

					<div className="text-input">
						<label className="text-input__label">{translate("ccs")}</label>
						<Select
							options={departmentSubsetList}
							value={values.departmentSubset}
							isLoading={departmentSubsetList.length === 0 ? true : false}
							onChange={(e) => {
								setValues({
									...values,
									departmentSubset: { label: e.label, value: e.value },
								});
							}}
							loadingMessage={() => "لطفا صبر کنید..."}
							placeholder=""
						/>
					</div>

					<div className="text-input">
						<label className="text-input__label">
							{translate("n_kind_ser")}
						</label>
						<Select
							options={serviceTypeList}
							value={values.serviceType}
							isLoading={serviceTypeList.length === 0 ? true : false}
							onChange={(e) => {
								setValues({
									...values,
									serviceType: { label: e.label, value: e.value },
								});
							}}
							loadingMessage={() => "لطفا صبر کنید..."}
							placeholder=""
						/>
					</div>

					<div className="text-input">
						<label className="text-input__label">{translate("cs")}</label>
						<Select
							options={serviceList}
							value={values.service}
							isLoading={serviceList.length === 0 ? true : false}
							onChange={(e) => {
								setValues({
									...values,
									service: { label: e.label, value: e.value },
								});
							}}
							loadingMessage={() => "لطفا صبر کنید..."}
							placeholder=""
						/>
					</div>

					<div className="text-input">
						<label className="text-input__label">{translate("cl")}</label>
						<input
							className="text-input__input"
							// value={values.cl}
							onChange={(e) => {
								setValues({
									...values,
									cl: e.target.value,
								});
							}}
						/>
					</div>

					<div className="text-input" style={{ width: "100%" }}>
						<label className="text-input__label">
							{translate("description")}
						</label>
						<textarea
							className="text-input__textarea"
							value={values.description}
							onChange={(e) => {
								setValues({
									...values,
									description: e.target.value,
								});
							}}
						></textarea>
					</div>

					<div className="button-container">
						<button className="button" onClick={() => callInsert.next()}>
							{translate("insert")}
						</button>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
