let moment = require("jalali-moment");

let enDate = new Date();
let dd = String(enDate.getDate()).padStart(2, "0");
let mm = String(enDate.getMonth() + 1).padStart(2, "0"); //January is 0!
let yyyy = enDate.getFullYear();


enDate = yyyy + "/" + mm + "/" + dd;

let faDate = moment().locale("fa").format("YYYY/MM/DD");

export {enDate, faDate}