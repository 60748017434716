import React, { Fragment, useEffect, useState } from "react";
import Table from "../../../../components/table";
import Select from "react-select";
import translate from "../../../../functions/translate";
import API from "../../../../api/baseUrl";
import { serialize, original } from "../../../../functions/helper";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";

export default function DentalStepFive() {
	const Login_User_Token = localStorage.getItem("user_login_token");
	const user_login_np = localStorage.getItem("user_login_np");

	const [toothNumbers, setToothNumbers] = useState("")

	const chartValues = useSelector((state) => state.chartValues);
	const dispatch = useDispatch();

	const handleUpadetValue = (value) => {
		dispatch({
			type: "UPDATE_CHRT_VALUES",
			payload: {
				descr: value,
			},
		});
	};

	useEffect(() => {

		if(chartValues.LNUM) {

			
			setToothNumbers(chartValues.LNUM.join(","))
		}
		}, [])

	return (
		<div className="dantal-chart__final">
			<table className="table">
				<tbody>
					{/* <tr>
						<td>{translate("cp")}</td>
						<td>{chartValues.cp}</td>
					</tr> */}
					<tr>
						<td>{translate("np")}</td>
						<td>{chartValues.np}</td>
					</tr>
					<tr>
						<td>{translate("np_expert")}</td>
						<td>{user_login_np}</td>
					</tr>
					<tr>
						<td>{translate("ns")}</td>
						<td>{chartValues.ns}</td>
					</tr>
					<tr>
						<td>{translate("ref_row")}</td>
						<td>{chartValues.ref_row}</td>
					</tr>
					<tr>
						<td>{translate("LNUM")}</td>
						<td>{chartValues.LNUM && chartValues.LNUM.join(",")}</td>
					</tr>
				</tbody>
			</table>
			<br />
			<div className="text-input">
				{/* <label className="text-input__label">{translate("descr")}</label> */}
				<textarea
					className="text-input__textarea"
					onChange={(e) => handleUpadetValue(e.target.value)}
					placeholder={translate("descr")}
				></textarea>
			</div>
		</div>
	);
}
