import {combineReducers} from 'redux';
import permissions from "./permissionReducer";
import translate from "./translateReducer";
import chartValues from "./chartValues";
import documentValues from "./documentValues";


export default combineReducers({
    permissions,
    translate,
    chartValues,
    documentValues
})