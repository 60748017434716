import React, { useState, useEffect } from "react";
import ChartOne from "../../../../components/charts/ChartOne";
import { original, serialize } from "../../../../functions/helper";
import API from "../../../../api/baseUrl";
import Select from "react-select";
import translate from "../../../../functions/translate";
import DocumentTable from "../../../../components/documentTable";
import { useDispatch, useSelector } from "react-redux";
import { FiSearch } from "react-icons/fi";

export default function DentalStepThree() {
	const Login_User_Token = localStorage.getItem("user_login_token");
	const Login_User_cp = localStorage.getItem("user_login_cp");

	const dispatch = useDispatch();

	const [personTableList, setPersonTableList] = useState([]);
	const [serviceList, setServiceList] = useState([]);
	const [departmentList, setDepartmentList] = useState([]);
	const [searchInput, setSearchInput] = useState("");

	const documentValues = useSelector(state => state.documentValues)

	const [dataLoading, setDataLoading] = useState(true);

	useEffect(() => {
		fetchData();
	}, [searchInput]);

	const fetchData = async () => {
		try {
			const [
				resData,
			] = await Promise.all([
				API.post(
					serialize({
						id: 4234,
						sp_param: `'${documentValues.cp}','${documentValues.ref_row}','${Login_User_cp}'`,
						Login_User_Token,
						Login_User_cp,
					}),
				),
			]);

			let resDataParse = original(resData.data, false);
		
			console.log(resDataParse);

			setPersonTableList(resDataParse);
		
			setDataLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	const handleSetValue = (values) => {
		dispatch({
			type: "UPDATE_DOCUMENTS_VALUES",
			payload: {
				id_images: values.id_images,
			},
		});
	};

	return (
		<div className="dantal-step-three">
			{/* <div className="serach-bar">
				<div className="text-input">
					<input
						className="text-input__input"
						placeholder="متن جستجو..."
						style={{ paddingLeft: 40, margin: 0 }}
						onChange={(e) => setSearchInput(e.target.value)}
					/>
					<spna className="text-input__icon">
						<FiSearch />
					</spna>
				</div>
			</div> */}
			{/* <div className="dental-step-form">
				<div className="text-input">
					<label className="text-input__label">{translate("cs")}</label>
					<Select
						options={serviceList}
						// value={values.service}
						isLoading={dataLoading}
						// onChange={(e) => {
						// 	setValues({
						// 		...values,
						// 		service: { label: e.label, value: e.value },
						// 	});
						// }}
						loadingMessage={() => "لطفا صبر کنید..."}
						placeholder=""
					/>
				</div>

				<div className="text-input">
					<label className="text-input__label">{translate("cc")}</label>
					<Select
						options={departmentList}
						// value={values.department}
						// isLoading={departmentList.length === 0 ? true : false}
						// onChange={(e) => {
						// 	setValues({
						// 		...values,
						// 		department: { label: e.label, value: e.value },
						// 	});
						// }}
						loadingMessage={() => "لطفا صبر کنید..."}
						placeholder=""
					/>
				</div>
			</div> */}
			<DocumentTable
				data={personTableList}
				loading={dataLoading}
				deteleItem={false}
				handleClickRow={handleSetValue}
				keySelect="id_images"
				disabledHead={true}
			/>
		</div>
	);
}
