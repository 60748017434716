import React from 'react';
import { Route, Redirect } from 'react-router-dom'

export default function PrivateRoute({ component: Component, ...rest }) {

    const token = localStorage.getItem("user_login_token");
    const user_cp = localStorage.getItem("user_login_cp");
    return (
        <Route
            {...rest}
            render={props =>
                token && user_cp ? (
                    <Component {...props} />
                ) : (
                        <Redirect to="/" />
                    )
            }
        />
    )
}
