const initialState = []

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case 'TRANSLATE_LIST':
        return [ ...payload ]

    default:
        return state
    }
}