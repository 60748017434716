import { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { FiLogOut, FiMenu } from "react-icons/fi";
import swal from "sweetalert";
import queryString from "query-string";

export default function Header() {
	let location = useLocation();
	const history = useHistory();
	const username = localStorage.getItem("user_login_np");
	const query = queryString.parse(location.search);

	useEffect(() => {
		handleActiveMenu();
	}, [location.pathname]);

	const handleActiveMenu = () => {
		[...document.querySelectorAll(".header__nav_item")].map((item) => {
			item.classList.remove("header__nav_item-active");
		});
		[...document.querySelectorAll(".header__nav_item")].map((item) => {
			if (
				(item.firstChild.getAttribute("href") === location.pathname) &
				item.firstChild
					.getAttribute("href")
					.indexOf(location.pathname.split("/")[2])
			) {
				item.classList.add("header__nav_item-active");
			}
		});
	};

	const handleLogout = () => {
		swal("", "آیا برای خروج مطمئن هستید؟", "warning", {
			button: "بله",
		}).then((value) => {
			if (value) {
				localStorage.clear();
				history.push("/");
			}
		});
	};

	const toggleNavMobile = () => {
		document.getElementById("sideNav").classList.add("mobile-show");
	};

	return (
		<header
			className="header"
			style={{ display: query.fullWidth ? "none" : "" }}
		>
			<div className="header__grid">
				<div>
					<button className="header__toggle-menu" onClick={toggleNavMobile}>
						<FiMenu />
					</button>
				</div>
				<div className="header__btns">
					<div className="header__btns_user">
						<span>{username}</span>
						<CgProfile />
					</div>
					<button className="header__btns_logout" onClick={handleLogout}>
						<FiLogOut />
						<span>خروج</span>
					</button>
				</div>
			</div>
		</header>
	);
}
