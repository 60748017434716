import API from "../../../api/baseUrl";
import { useState, useEffect, Fragment } from "react";
import Select from "react-select";
import InputMask from "react-input-mask";
import swal from "sweetalert";

import { serialize, original } from "../../../functions/helper";
import VisitList from "./VisitList";
import weekApi from '../../../api/week';
import translate from '../../../functions/translate';

const dateMask = {
  mask: "9999/99/99",
  maskChar: "_",
  alwaysShowMask: false,
  formatChars: {
    9: "[0-9]",
    a: "[A-Za-z]",
    "*": "[A-Za-z0-9]",
  },
  permanents: [2, 5], // permanents is an array of indexes of the non-editable characters in the mask
};

const timeMask = {
  mask: "99:99",
  maskChar: "_",
  alwaysShowMask: false,
  formatChars: {
    9: "[0-9]",
    // a: "[A-Za-z]",
    "*": "[A-Za-z0-9]",
  },
};

export default function Visit() {
  const Login_User_Token = localStorage.getItem("user_login_token");
  const Login_User_cp = localStorage.getItem("user_login_cp");

  const [data, setData] = useState({
    sdate: "",
    c_week: "",
    c_kind: "",
    cp: "",
    cs: "",
    cp_expert: "",
    cp_tecnician: "",
    c_unit: "",
    stime: "",
    etime: "",
    time_c: "",
    time_o: "",
    descr: "",
    cr: "",
  });

  const [personList, setPersonList] = useState([]);
  const [service, setService] = useState([]);
  const [specialist, setSpecialist] = useState([]);
  const [technicianList, setTechnicianList] = useState([]);
  const [resultList, setًResultList] = useState([]);
  const [kindList, setًkindList] = useState([]);
  const [userName, setUserName] = useState("");
  const [userLoading, setUserLoading] = useState(false);
  const [dataResult, setDataResult] = useState(null);
  const [values, setValues] = useState({
    service: null,
    special: null,
    kind: null,
    technician: null,
    result: null,
    sdate: "",
  });
  // console.log(data);

  useEffect(() => {
    fetchData();
  }, []);

  const handleInsertVisit = async (e) => {
    const button = e.target;
    try {
      button.setAttribute("disabled", "disabled");
      e.preventDefault();
      const res = await API.post(
        serialize({
          id: 2350,
          sp_param: `'${data.sdate}','${data.c_week}','${data.c_kind}','${data.cp}','${data.cs}','${data.cp_expert}','${data.cp_tecnician}','${data.c_unit}','${data.stime}','${data.etime}','${data.time_c}','${data.time_o}','${data.descr}','${data.cr}','${Login_User_cp}'`,
          Login_User_Token,
          Login_User_cp,
        })
      );
      let resParse = original(res.data, false);

      console.log(resParse);

      setDataResult(resParse[0]);

      if (resParse[0].s_result === "0" || resParse[0].s_result < 0) {
        swal("", `${resParse[0].s_message}`, "warning");
        button.removeAttribute("disabled");
        return;
      }
      if (resParse[0].s_result > 0) {
        swal("", "نوبت شما با موفقیت ثبت شد", "success");
        clearInputValue();
        button.removeAttribute("disabled");
      }
    } catch (error) {
      console.log(error);
      button.removeAttribute("disabled");
    }
  };

  const fetchData = async () => {
    try {
      const [
        resService,
        resSpecialist,
        resTechnician,
        resResult,
        resKind,
      ] = await Promise.all([
        API.post(
          serialize({
            id: 1506,
						sp_param: `'','','','${Login_User_cp}'`,
            Login_User_Token,
            Login_User_cp,
          })
        ),
        API.post(
          serialize({
            id: 1514,
            Login_User_Token,
            Login_User_cp,
            sp_param: `'${Login_User_cp}'`,
          })
        ),
        API.post(
          serialize({
            id: 1510,
						sp_param: `'','','','${Login_User_cp}'`,
            Login_User_Token,
            Login_User_cp,
          })
        ),
        API.post(
          serialize({
            id: 1603,
            Login_User_Token,
            Login_User_cp,
          })
        ),
        API.post(
          serialize({
            id: 1552,
            Login_User_Token,
            Login_User_cp,
            sp_param: `'${Login_User_cp}'`,
          })
        ),
      ]);

      let resServiceParse = original(resService.data, false);
      let resSpecialistParse = original(resSpecialist.data, false);
      let resTechnicianParse = original(resTechnician.data, false);
      let resResultParse = original(resResult.data, false);
      let resKindParse = original(resKind.data, false);

      let serviceArr = [];
      let specialistArr = [];
      let technicianArr = [];
      let resultArr = [];
      let kindArr = [];

      resServiceParse.map((item) => {
        serviceArr.push({ label: `${item.ns} (${item.cs})`, value: item.cs });
      });
      resSpecialistParse.map((item) => {
        specialistArr.push({
          label: `${item.name_expert} (${item.cp})`,
          value: item.cp,
        });
      });
      resTechnicianParse.map((item) => {
        technicianArr.push({
          label: `${item.n_technician} (${item.cp})`,
          value: item.cp,
        });
      });
      resResultParse.map((item) => {
        resultArr.push({
          label: item.n_combo,
          value: item.c_combo,
        });
      });
      resKindParse.map((item) => {
        kindArr.push({
          label: `${item.n_combo} (${item.c_combo})`,
          value: item.c_combo,
        });
      });
      setService(serviceArr);
      setSpecialist(specialistArr);
      setTechnicianList(technicianArr);
      setًResultList(resultArr);
      setًkindList(kindArr);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleFindUser = (value) => {
  //   let x = value.which || value.keyCode;
  //   // console.log(x);
  //   if (x === 13) {
  //     // if(userName === "") return
  //     setUserLoading(true);
  //     API.post(
  //       serialize({
  //         id: 2467,
  //         sp_param: `'${userName}','20','${Login_User_cp}'`,
  //         Login_User_Token,
  //         Login_User_cp,
  //       })
  //     )
  //       .then((response) => {
  //         let res = original(response.data, false);
  //         let personArr = [];
  //         res.map((item) => {
  //           personArr.push({
  //             label: `${item.np} (${item.cp})`,
  //             value: item.cp,
  //           });
  //         });
  //         // console.log(res);
  //         setPersonList(personArr);
  //         setUserLoading(false);
  //       })
  //       .catch((err) => {
  //         // this.setState({connectingStatus: false})
  //         swal("", `${err}`, "warning");
  //         setUserLoading(false);
  //       });
  //   }
  // };

  const handleUserInputChange = (e) => {
    // console.log(e);
    if (e === "") {
      setPersonList([]);
      setUserLoading(false);
      return;
    }
    setUserLoading(true);

    API.post(
      serialize({
        id: 2467,
        sp_param: `'${e}','20','${Login_User_cp}'`,
        Login_User_Token,
        Login_User_cp,
      })
    )
      .then((response) => {
        let res = original(response.data, false);
        let personArr = [];
        res.map((item) => {
          personArr.push({
            label: `${item.np} (${item.cp})`,
            value: item.cp,
          });
        });
        // console.log(res);
        setPersonList(personArr);
        setUserLoading(false);
      })
      .catch((err) => {
        // this.setState({connectingStatus: false})
        swal("", `${err}`, "warning");
        setUserLoading(false);
      });
  };

  const clearInputValue = () => {
    setValues({
      service: null,
      special: null,
      kind: null,
      technician: null,
      result: null,
      // sdate: ""
    });
    setData({
      sdate: "",
      c_week: "",
      c_kind: "",
      cp: "",
      cs: "",
      cp_expert: "",
      cp_tecnician: "",
      c_unit: "",
      stime: "",
      etime: "",
      time_c: "",
      time_o: "",
      descr: "",
      cr: "",
    });
    [...document.querySelectorAll(".text-input__input")].map((item) => {
      item.value = "";
    });
  };

  return (
    <Fragment>
      <div className="panel-head">
        <h2 className="panel-head__title">نوبت دهی</h2>
      </div>
      <div className="visit-page panel-page__paper">
        <form className="visit-page__form">
          <div className="text-input">
            <label className="text-input__label">{translate('cp')}</label>
            <Select
              options={personList}
              isLoading={userLoading}
              onChange={(e) => {
                if (e) setData({ ...data, cp: e.value });
                else setData({ ...data, cp: "" });
              }}
              onInputChange={(e) => handleUserInputChange(e)}
              loadingMessage={() => "لطفا صبر کنید"}
              // onKeyDown={handleFindUser}
              placeholder=""
              isClearable={true}
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('cs')}</label>
            <Select
              options={service}
              value={values.service}
              isLoading={service.length === 0 ? true : false}
              onChange={(e) => {
                setValues({
                  ...values,
                  service: { label: e.label, value: e.value },
                });
                setData({ ...data, cs: e.value });
              }}
              loadingMessage={() => "لطفا صبر کنید..."}
              placeholder=""
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('cp_expert')}</label>
            <Select
              options={specialist}
              value={values.special}
              isLoading={specialist.length === 0 ? true : false}
              onChange={(e) => {
                setValues({
                  ...values,
                  special: { label: e.label, value: e.value },
                });
                setData({ ...data, cp_expert: e.value });
              }}
              loadingMessage={() => "لطفا صبر کنید..."}
              placeholder=""
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('cp_tecnician')}</label>
            <Select
              options={technicianList}
              value={values.technician}
              isLoading={technicianList.length === 0 ? true : false}
              onChange={(e) => {
                setValues({
                  ...values,
                  technician: { label: e.label, value: e.value },
                });
                setData({ ...data, cp_tecnician: e.value });
              }}
              loadingMessage={() => "لطفا صبر کنید..."}
              placeholder=""
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('c_kind')}</label>
            <Select
              options={kindList}
              value={values.kind}
              isLoading={kindList.length === 0 ? true : false}
              onChange={(e) => {
                setValues({
                  ...values,
                  kind: { label: e.label, value: e.value },
                });
                setData({ ...data, c_kind: e.value });
              }}
              loadingMessage={() => "لطفا صبر کنید..."}
              placeholder=""
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('c_week')}</label>
            <Select
              options={weekApi}
              value={values.c_week}
              isLoading={weekApi.length === 0 ? true : false}
              onChange={(e) => {
                setValues({
                  ...values,
                  c_week: { label: e.label, value: e.value },
                });
                setData({ ...data, c_week: e.value });
              }}
              loadingMessage={() => "لطفا صبر کنید..."}
              placeholder=""
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('sdate')}</label>
            <InputMask
              {...dateMask}
              value={data.sdate}
              className="text-input__input ltr"
              onChange={(e) => {
                setData({ ...data, sdate: e.target.value });
              }}
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('time_c')}</label>
            <InputMask
              {...timeMask}
              value={data.time_c}
              className="text-input__input ltr"
              onChange={(e) => {
                setData({ ...data, time_c: e.target.value });
              }}
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('time_o')}</label>
            <InputMask
              {...timeMask}
              value={data.time_o}
              className="text-input__input ltr"
              onChange={(e) => {
                setData({ ...data, time_o: e.target.value });
              }}
              required
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('stime')}</label>
            <InputMask
              {...timeMask}
              value={data.stime}
              className="text-input__input ltr"
              onChange={(e) => {
                setData({ ...data, stime: e.target.value });
              }}
              required
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('etime')}</label>
            <InputMask
              {...timeMask}
              value={data.etime}
              className="text-input__input ltr"
              onChange={(e) => {
                setData({ ...data, etime: e.target.value });
              }}
              required
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('c_unit')}</label>
            <input
              type="text"
              value={data.c_unit}
              className="text-input__input"
              defaultValue="0"
              onChange={(e) => {
                setData({ ...data, c_unit: e.target.value });
              }}
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('result')}</label>
            <Select
              options={resultList}
              value={values.result}
              isLoading={resultList.length === 0 ? true : false}
              onChange={(e) => {
                setValues({
                  ...values,
                  result: { label: e.label, value: e.value },
                });
                setData({ ...data, cr: e.value });
              }}
              loadingMessage={() => "لطفا صبر کنید..."}
              placeholder=""
            />
          </div>

          <div className="text-input" style={{ width: "100%" }}>
            <label className="text-input__label">{translate('descr')}</label>
            <textarea
              className="text-input__textarea"
              value={data.descr}
              maxLength="1000"
              onChange={(e) => {
                setData({ ...data, descr: e.target.value });
              }}
            ></textarea>
          </div>

          <div className="button-container">
            <button
              className="button"
              onClick={handleInsertVisit}
              type="button"
            >
              ثبت
            </button>
          </div>
        </form>

        <VisitList update={dataResult} />
      </div>
    </Fragment>
  );
}
