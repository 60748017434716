import API from "../../api/baseUrl";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import swal from "sweetalert";
import Captcha from "demos-react-captcha";
import CaptchaNumeric from "react-numeric-captcha";

import { serialize, original } from "../../functions/helper";
import config from "../../config.json";
import { enDate } from "../../functions/date";

import "../../assets/sass/authStyle.scss";
import faToEnChar from "../../functions/replaceFaToEn";

import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

export default function Login() {
  const history = useHistory();

  const [data, setdata] = useState({ id: 1112, username: "", password: "" });
  const [nationalCodeFlag, setNationalCodeFlag] = useState(false);
  const [phoneNumberFlag, setPhoneNumberFlag] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const [captcha, setCaptcha] = useState(false);

  const handleLogin = async (e) => {
    const button = e.target;
    button.setAttribute("disabled", "disabled");
    e.preventDefault();

    if (config.show_login_captcha === 1) {
      if (!captcha && config.show_login_captcha === 1) {
        swal("", `لطفا متن تصویر را درست وارد کنید`, "warning", {
          button: "متوجه شدم",
        });
        button.removeAttribute("disabled");
        return;
      }
    }

    if (config.show_login_captcha_numeric === 1) {
      if (!captcha && config.show_login_captcha_numeric === 1) {
        swal("", `لطفا متن تصویر را درست وارد کنید`, "warning", {
          button: "متوجه شدم",
        });
        button.removeAttribute("disabled");
        return;
      }
    }

    API.post(serialize(data))
      .then((response) => {
        let {
          user_login_cp,
          user_login_message,
          user_login_token,
          user_login_np,
        } = original(response.data);
        // console.log(original(response.data));
        if (user_login_cp !== "0") {
          if (!checkPermition(user_login_cp, user_login_token)) {
            button.removeAttribute("disabled");
            return;
          }
          // checkPermition(user_login_cp, user_login_token)
          localStorage.setItem("user_login_cp", user_login_cp);
          localStorage.setItem("user_login_token", user_login_token);
          localStorage.setItem("user_login_np", user_login_np);
          button.removeAttribute("disabled");
          history.push("/panel/dashboard");
        } else {
          // this.setState({connectingStatus: false})
          button.removeAttribute("disabled");
          swal("", `${user_login_message}`, "warning");
        }
      })
      .catch((err) => {
        // this.setState({connectingStatus: false})
        console.log(err);
        swal("", `${err}`, "warning", { button: "متوجه شدم" });
        button.removeAttribute("disabled");
      });
  };

  const handleLoginWithNationalCode = async (e) => {
    const button = e.target;
    button.setAttribute("disabled", "disabled");
    e.preventDefault();

    if (!data.username) {
      swal("", `لطفا کد ملی خود را در قسمت خواسته شده وارد کنید`, "warning", {
        button: "متوجه شدم",
      });
      setNationalCodeFlag(true);
      button.removeAttribute("disabled");
      return;
    } else {
      setNationalCodeFlag(false);
    }

    if (!data.password) {
      swal(
        "",
        `لطفا شماره همراه خود را در قسمت خواسته شده وارد کنید`,
        "warning",
        {
          button: "متوجه شدم",
        }
      );
      setPhoneNumberFlag(true);
      button.removeAttribute("disabled");
      return;
    } else {
      setPhoneNumberFlag(false);
    }

    try {
      const res = await API.post(
        serialize({
          id: 1705,
          sp_param: `'0','0','3','101','machine_nm','machine_ip','machine_id','${data.username}','${data.password}'`,
          Login_User_Token: 0,
          Login_User_cp: 0,
        })
      );

      let { user_login_cp, s_message, user_login_token, user_login_np } =
        original(res.data);

      if (user_login_token && user_login_np) {
        // console.log(original(res.data));

        localStorage.setItem("user_login_cp", user_login_cp);
        localStorage.setItem("user_login_token", user_login_token);
        localStorage.setItem("user_login_np", user_login_np);
        button.removeAttribute("disabled");
        history.push({
          pathname: "/visit",
          state: { nationalCode: data.username, phoneNumber: data.password },
        });
      } else {
        button.removeAttribute("disabled");
        swal("", `${s_message}`, "warning");
      }
      button.removeAttribute("disabled");
    } catch (err) {
      console.log(err);
      swal("", `${err}`, "warning", { button: "متوجه شدم" });
      button.removeAttribute("disabled");
    }
  };

  const checkPermition = async (user_login_cp, user_login_token) => {
    // check alert show permition
    const resPer = await API.post(
      serialize({
        id: 1500,
        sp_param: ``,
        Login_User_Token: user_login_token,
        Login_User_cp: user_login_cp,
      })
    );
    let resultPer = original(resPer.data, false);

    if (resultPer[0].act_alarm_after_login === "False") return true;

    const res = await API.post(
      serialize({
        id: 1589,
        sp_param: `'','','${user_login_cp}'`,
        Login_User_Token: user_login_token,
        Login_User_cp: user_login_cp,
      })
    );

    let result = original(res.data, false);
    // console.log(result);
    // console.log(result);

    if (result[0].exit_program_after_alarm === "False") {
      swal("", `${result[0].s_alarm}`, "success", { button: "متوجه شدم" });
      return true;
    } else if (result[0].exit_program_after_alarm === "True") {
      swal("", `${result[0].s_alarm}`, "warning", { button: "متوجه شدم" });
      return false;
    } else {
      swal("", `تاریخ اعتبار مشخص نیست`, "warning", { button: "متوجه شدم" });
      return false;
    }
  };

  const onChangeCaptcha = (value) => {
    if (value) {
      setCaptcha(value);
    } else {
      setCaptcha(false);
    }
  };

  const onChangeInput = (e) => {
    setdata({ ...data, [e.target.name]: faToEnChar(e.target.value) });
  };

  return (
    <div
      className="login-page"
      style={{
        background: `linear-gradient(45deg,${
          config.auth_back_color_1
            ? config.auth_back_color_1
            : "rgba(86, 0, 110, 1)"
        } 10%,${
          config.auth_back_color_2
            ? config.auth_back_color_2
            : "rgba(33, 150, 243, 1)"
        } 100%)`,
      }}
    >
      <div className="login-page__box">
        <h1>{config.header}</h1>
        <h2 className="login-page__title">{config.header_login}</h2>
        <form className="login-page__form">
          <div className="text-input">
            {/* <label for="username" className="text-input__label">نام کاربری</label> */}
            <input
              type="text"
              id="username"
              name="username"
              className="text-input__input ltr"
              onChange={onChangeInput}
              placeholder={
                config.login_input_name ? config.login_input_name : "username"
              }
            />
            {nationalCodeFlag && (
              <span className="error-message">
                لطفا کد ملی خود را وارد کنید
              </span>
            )}
          </div>

          <div className="text-input">
            {/* <label for="username" className="text-input__label">کلمه عبور</label> */}
            <input
              type={showPass ? "text" : "password"}
              id="password"
              name="password"
              className="text-input__input ltr"
              onChange={onChangeInput}
              placeholder={
                config.login_input_pass ? config.login_input_pass : "password"
              }
              style={{ paddingRight: 46 }}
            />
            <span
              className="text-input__show-pass"
              onClick={() => setShowPass(!showPass)}
            >
              {showPass ? <AiFillEyeInvisible /> : <AiFillEye />}
            </span>

            {phoneNumberFlag && (
              <span className="error-message">
                لطفا شماره همراه خود را وارد کنید
              </span>
            )}
          </div>
          {config.show_login_captcha === 1 && (
            <Captcha onChange={onChangeCaptcha} placeholder="کد تصویر" />
          )}

          {config.show_login_captcha_numeric === 1 && (
            <CaptchaNumeric onChange={onChangeCaptcha} placeholder="کد تصویر" />
          )}

          <button className="login-page__form_btn" onClick={handleLogin}>
            {config.login_button_text ? config.login_button_text : "ورود"}
          </button>

          <div className="login-page__form_links">
            {config.show_register_link === 1 && (
              <Link to="/signup">ثبت نام</Link>
            )}
            {config.show_change_pass_link === 1 && (
              <Link to="/">فراموشی کلمه عبور</Link>
            )}
            {config.show_fast_visit_link_in_login === 1 && (
              <button onClick={handleLoginWithNationalCode}>نوبت دهی</button>
            )}
          </div>
        </form>
        <div className="login-page__box_footer">
          <span>{enDate}</span>
        </div>
      </div>
    </div>
  );
}
