import { useState, useEffect, Fragment } from "react";
import Select from "react-select";
import InputMask from "react-input-mask";
import swal from "sweetalert";

import API from "../../../api/baseUrl";
import { serialize, original } from "../../../functions/helper";
import CheckList from "./CheckList";
import translate from "../../../functions/translate";

const dateMask = {
  mask: "9999/99/99",
  maskChar: "_",
  alwaysShowMask: false,
  formatChars: {
    9: "[0-9]",
    a: "[A-Za-z]",
    "*": "[A-Za-z0-9]",
  },
  permanents: [2, 5], // permanents is an array of indexes of the non-editable characters in the mask
};

const timeMask = {
  mask: "99:99",
  maskChar: "_",
  alwaysShowMask: false,
  formatChars: {
    9: "[0-9]",
    // a: "[A-Za-z]",
    "*": "[A-Za-z0-9]",
  },
};

const kindList = [
  { label: "چک دریافتی", value: 1 },
  { label: "چک پرداختی", value: 2 },
];

const bankList = [
  { label: "تجارت", value: 1 },
  { label: "ملت", value: 2 },
  { label: "ملی", value: 3 },
  { label: "رفاه", value: 4 },
];

export default function Check() {
  const Login_User_Token = localStorage.getItem("user_login_token");
  const Login_User_cp = localStorage.getItem("user_login_cp");

  const [data, setData] = useState({
    cp: "",
    payment: "",
    c_kind: { label: "", value: 0 },
    due_date: "",
    sdate: "",
    c_bnk_c: { label: "", value: 0 },
    accnu: "",
    num: "",
    acc_owner: "",
    c_bnk: "",
    descr: "",
    c_branch_bnk: "",
    cp_receiver: "",
    c_bnk_receiver: ""
  });

  const [dataResult, setDataResult] = useState(null);
  const [personList, setPersonList] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [userName, setUserName] = useState("");

  // const [values, setValues] = useState({
  //   kind: null,

  // })

  const handleInsertDeposit = async (e) => {
    const button = e.target;
    e.preventDefault();
    try {
      button.setAttribute("disabled", "disabled");

      const res = await API.post(
        serialize({
          id: 2631,
          sp_param: `'${data.cp}','${data.payment}','${data.c_kind.value}','${data.due_date}','${data.sdate}','${data.c_bnk_c.value}','${data.accnu}','${data.num}','${data.acc_owner}','${data.c_bnk}','${data.descr}','${data.c_branch_bnk}','${data.cp_receiver}','${data.c_bnk_receiver}','${Login_User_cp}'`,
          Login_User_Token,
          Login_User_cp,
        })
      );
      let resParse = original(res.data, false);

      console.log(resParse);

      setDataResult(resParse[0]);

      if (resParse[0].s_result === "0" || resParse[0].s_result < 0) {
        swal("", `${resParse[0].s_param}`, "warning");
        button.removeAttribute("disabled");
        return;
      }
      if (resParse[0].s_result > 0) {
        swal("", "نوبت شما با موفقیت ثبت شد", "success");
        clearInputValue();
        button.removeAttribute("disabled");
      }
    } catch (error) {
      console.log(error);
      button.removeAttribute("disabled");
    }
  };

  const handleUserInputChange = (e) => {
    // console.log(e);
    if (e === "") {
      setPersonList([]);
      setUserLoading(false);
      return
    }

    setUserLoading(true);
    API.post(
      serialize({
        id: 2467,
        sp_param: `'${e}','20','${Login_User_cp}'`,
        Login_User_Token,
        Login_User_cp,
      })
    )
      .then((response) => {
        let res = original(response.data, false);
        let personArr = [];
        res.map((item) => {
          personArr.push({
            label: `${item.np} (${item.cp})`,
            value: item.cp,
          });
        });
        // console.log(res);
        setPersonList(personArr);
        setUserLoading(false);
      })
      .catch((err) => {
        // this.setState({connectingStatus: false})
        swal("", `${err}`, "warning");
        setUserLoading(false);
      });
  };

  const clearInputValue = () => {
    setData({
      cp: "",
      payment: "",
      c_kind: { label: "", value: 0 },
      due_date: "",
      sdate: "",
      c_bnk_c: { label: "", value: 0 },
      accnu: "",
      num: "",
      acc_owner: "",
      c_bnk: "",
      descr: "",
    });
    [...document.querySelectorAll(".text-input__input")].map((item) => {
      item.value = "";
    });
  };

  console.log(data);

  return (
    <Fragment>
      <div className="panel-head">
        <h2 className="panel-head__title">چک</h2>
      </div>
      <div className="deposit-page panel-page__paper">
        <form className="visit-page__form">
          <div className="text-input">
            <label className="text-input__label"> {translate('cp')}</label>
            <Select
              options={personList}
              isLoading={userLoading}
              onChange={(e) => {
                if (e) setData({ ...data, cp: e.value });
                else setData({ ...data, cp: "" });
              }}
              onInputChange={(e) => handleUserInputChange(e)}
              loadingMessage={() => "لطفا صبر کنید"}
              // onKeyDown={handleFindUser}
              placeholder=""
              isClearable={true}
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('payment')}</label>
            <input
              type="text"
              className="text-input__input ltr"
              onChange={(e) => {
                setData({ ...data, payment: e.target.value });
              }}
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('due_date')}</label>
            <InputMask
              {...dateMask}
              className="text-input__input ltr"
              onChange={(e) => {
                setData({ ...data, due_date: e.target.value });
              }}
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('sdate')}</label>
            <InputMask
              {...dateMask}
              className="text-input__input ltr"
              onChange={(e) => {
                setData({ ...data, sdate: e.target.value });
              }}
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('c_kind')}</label>
            <Select
              options={kindList}
              value={data.c_kind}
              isLoading={kindList.length === 0 ? true : false}
              onChange={(e) => {
                setData({ ...data, c_kind: e });
              }}
              loadingMessage={() => "لطفا صبر کنید..."}
              placeholder=""
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('c_bnk_c')}</label>
            <Select
              options={bankList}
              value={data.c_bnk_c}
              isLoading={bankList.length === 0 ? true : false}
              onChange={(e) => {
                setData({ ...data, c_bnk_c: e });
              }}
              loadingMessage={() => "لطفا صبر کنید..."}
              placeholder=""
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('accnu')} </label>
            <InputMask
              // {...dateMask}
              mask={"9999999999"}
              className="text-input__input ltr"
              onChange={(e) => {
                setData({ ...data, accnu: e.target.value });
              }}
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('num')} </label>
            <InputMask
              // {...dateMask}
              className="text-input__input ltr"
              onChange={(e) => {
                setData({ ...data, num: e.target.value });
              }}
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('acc_owner')} </label>
            <InputMask
              // {...dateMask}
              className="text-input__input ltr"
              onChange={(e) => {
                setData({ ...data, acc_owner: e.target.value });
              }}
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('c_bnk')} </label>
            <InputMask
              // {...dateMask}
              className="text-input__input ltr"
              onChange={(e) => {
                setData({ ...data, c_bnk: e.target.value });
              }}
            />
          </div>

          <div className="text-input" style={{ width: "100%" }}>
            <label className="text-input__label">{translate('descr')}</label>
            <textarea
              className="text-input__textarea"
              maxLength="1000"
              onChange={(e) => {
                setData({ ...data, descr: e.target.value });
              }}
            ></textarea>
          </div>

          <div className="button-container">
            <button
              className="button"
              onClick={handleInsertDeposit}
              type="button"
            >
              ثبت
            </button>
          </div>
        </form>

        <CheckList update={dataResult} />
      </div>
    </Fragment>
  );
}
