import axios from "axios";
import swal from "sweetalert";
import { serialize, original, authCheck } from "../functions/helper";
import config from "../config.json";

const instance = axios.create({
  baseURL: `${config.url}:${config.port}`,
  // timeout: 10000,
});

// axios.defaults.headers.common['Access-Control-Allow-Origin:'] = "*";

instance.interceptors.response.use(
  function (response) {

    // console.log(response);

    if (!authCheck(original(response.data))) {
      window.localStorage.clear();
      swal(
        "",
        "کاربر گرامی اعتبار زمان پنل کاربری شما به پایان رسیده, لطفا دوباره وارد شوید.", {
          button: "بسیار خب"
        }
      ).then((value) => {
        if(value) {
          window.location = "/";
        }
      });
      return;
    }
    return response;
  },
  function (err) {
    console.log(err);
    return Promise.reject(err);
  }
);


export default instance;
