import { Fragment, useEffect, useState } from "react";

import { original, serialize } from "../../../functions/helper";
import API from "../../../api/baseUrl";
import loader from "../../../assets/loader.svg";
import queryString from "query-string";
import translate from "../../../functions/translate";

import config from "../../../config.json";

// import { MdUpdate } from "react-icons/md";
import { BiUser } from "react-icons/bi";
import { FaUserMd } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { faDate } from "../../../functions/date";
import { RiFullscreenLine } from "react-icons/ri";

export default function Appointments() {
	const Login_User_Token = localStorage.getItem("user_login_token");
	const Login_User_cp = localStorage.getItem("user_login_cp");
	const location = useLocation();

	const query = queryString.parse(location.search);

	const [timer, setTimer] = useState(60);
	const [treatmentList, setTreatmentList] = useState([]);
	const [examinationList, setExaminationList] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchData();
		clearInterval(window.appointmentsTimer);

		window.appointmentsTimer = window.setInterval(() => {
			fetchData();
			// console.log(timer);
		}, config.appoinment_reload_timer_min * 60 * 1000);
	}, []);

	// const handleUpdate = () => {
	// 	fetchData();
	// 	setUpdateFlag(Math.random() * updateFlag);
	// };

	const fetchData = async () => {
		try {
			setLoading(true);
			const [resTreatment, resExamination] = await Promise.all([
				API.post(
					serialize({
						id: 1703,
						sp_param: `'${faDate}','${Login_User_cp}'`,
						Login_User_Token,
						Login_User_cp,
					}),
				),
				API.post(
					serialize({
						id: 1704,
						sp_param: `'${faDate}','${Login_User_cp}'`,
						Login_User_Token,
						Login_User_cp,
					}),
				),
			]);
			let resTreatmentParse = original(resTreatment.data, false);
			let resExaminationParse = original(resExamination.data, false);
			setTreatmentList(resTreatmentParse);
			setExaminationList(resExaminationParse);
			setLoading(false);
		} catch (error) {
			// swal("", `${error}`, "warning");
			console.log(error);
		}
	};

	const handleOpenNewWindow = () => {
		var novoForm = window.open(
			`${window.location}?fullWidth=true`,
			"monitor",
			"width=2200,height=1200,location=no,menubar=no,status=no,titilebar=no,resizable=no",
		);
	};

	return (
		<Fragment>
			{/* <div
				className="panel-head"
				style={{ display: query.fullWidth ? "none" : "" }}
			>
				<h2 className="panel-head__title">همه وقت ها</h2>
				<div className="panel-head__full-width">
					<button
						onClick={handleOpenNewWindow}
						className="panel-head__full-width-btn"
					>
						<RiFullscreenLine />
					</button>
					<span className="panel-head__full-width_tooltip">
						{translate("full_width")}
					</span>
				</div>
			</div> */}
			<div className="appointments-page panel-page__paper">
				<div style={{ textAlign: "left" }}>
					<div className="panel-head__full-width">
						<button
							onClick={handleOpenNewWindow}
							className="panel-head__full-width-btn"
						>
							<RiFullscreenLine />
						</button>
						<span className="panel-head__full-width_tooltip">
							{translate("full_width")}
						</span>
					</div>
				</div>
				{/* <div className="appointments-page__form">
					<div className="text-input">
						<input className="text-input__input" value={faDate} />
					</div>
					<div className="text-input" style={{ width: "85px" }}>
						<input
							className="text-input__input"
							style={{ textAlign: "center" }}
							value={timer}
							onChange={(e) => setTimer(e.target.value)}
						/>
					</div>
					<button
						className="appointments-page__form_btn"
						onClick={handleUpdate}
					>
						<MdUpdate />
					</button>
				</div> */}

				<div className="appointments">
					<div className="appointments__grid">
						<div className="appointments__grid_col">
							<div className="appointments__grid_col-head">
								<h3 className="appointments__title">لیست درمان</h3>
								<ul className="appointments-page__colors">
									<li className="green">در حال انجام درمان</li>
									<li className="yellow">در انتظار درمان</li>
									<li className="white">در صف انتظار</li>
								</ul>
							</div>

							<div className="appointments__box">
								{!loading ? (
									<ul className="appointments__box_list">
										{treatmentList.length ? (
											treatmentList.map((item, index) => (
												<li
													className={`${item.c_color === "102" && "yellow"} ${
														item.c_color === "101" && "green"
													}`}
													key={index}
												>
													<div>
														<BiUser />
														<span>{item.np}</span>
													</div>
													<div>
														<FaUserMd />
														<span>{item.np_expert}</span>
													</div>
												</li>
											))
										) : (
											<li>
												<div>
													<span>محتوایی برای نمایش وجود ندارد</span>
												</div>
											</li>
										)}
									</ul>
								) : (
									<div className="loader-container">
										<img src={loader} />
									</div>
								)}
							</div>
						</div>
						<div className="appointments__grid_col">
							<div className="appointments__grid_col-head">
								<h3 className="appointments__title">لیست معاینه</h3>
							</div>
							<div className="appointments__box">
								{!loading ? (
									<ul className="appointments__box_list">
										{examinationList.length ? (
											examinationList.map((item, index) => (
												<li
													className={`${item.c_color === "102" && "yellow"} ${
														item.c_color === "101" && "green"
													}`}
													key={index}
												>
													<div>
														<BiUser />
														<span>{item.np}</span>
													</div>
													<div>
														<FaUserMd />
														<span>{item.np_expert}</span>
													</div>
												</li>
											))
										) : (
											<li>
												<div>
													<span>محتوایی برای نمایش وجود ندارد</span>
												</div>
											</li>
										)}
									</ul>
								) : (
									<div className="loader-container">
										<img src={loader} />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
