import { useEffect, useState } from "react";
import swal from "sweetalert";
import Select from "react-select";
import InputMask from "react-input-mask";

import API from "../../../api/baseUrl";
import { serialize, original } from "../../../functions/helper";
// import loader from "../../../assets/loader.svg";
import translate from "../../../functions/translate";
import weekApi from "../../../api/week";
import { faDate } from "../../../functions/date";
import config from "../../../config.json";


const dateMask = {
  mask: "9999/99/99",
  maskChar: "_",
  alwaysShowMask: false,
  formatChars: {
    9: "[0-9]",
    a: "[A-Za-z]",
    "*": "[A-Za-z0-9]",
  },
  permanents: [2, 5],
};

export default function UserVisit() {
  const Login_User_Token = localStorage.getItem("user_login_token");
  const Login_User_cp = localStorage.getItem("user_login_cp");

  const [services, setServices] = useState([]);
  const [experts, setExperts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allVisits, setAllVisits] = useState([]);
  const [visitFuture, setVisitFuture] = useState([]);
  const [visitOn, setVisitOn] = useState([]);
  const [values, setValues] = useState({
    fday: faDate,
    tday: faDate,
    c_week: { label: "", value: "" },
    cs: { label: "", value: "" },
    cp_expert: { label: "", value: "" },
    desc: ""
  });
  const [valuesVisit, setValuesVisit] = useState({
    fday: "",
    tday: "",
    c_week: "",
    cs: "",
    cp_expert: "",
    desc: ""
  });

  useEffect(() => {
    fetchData();
    fetchUpdateData();
  }, []);

  const fetchData = async () => {
    try {
      const [resService, resExpert] = await Promise.all([
        API.post(
          serialize({
            id: 1581,
            sp_param: `'${Login_User_cp}'`,
            Login_User_Token,
            Login_User_cp,
          })
        ),
        API.post(
          serialize({
            id: 1582,
            sp_param: `'${Login_User_cp}'`,
            Login_User_Token,
            Login_User_cp,
          })
        ),
      ]);
      let resServiceParse = original(resService.data, false);
      let resExpertParse = original(resExpert.data, false);

      let serviceArr = [];
      let expertArr = [];

      resServiceParse.map((item) => {
        serviceArr.push({ label: `${item.ns} (${item.cs})`, value: item.cs });
      });
      resExpertParse.map((item) => {
        expertArr.push({
          label: `${item.n_expert} (${item.cp})`,
          value: item.cp,
        });
      });
      setServices(serviceArr);
      setExperts(expertArr);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUpdateData = async () => {
    try {
      const [resAllVisit, resVisitFuture] = await Promise.all([
        API.post(
          serialize({
            id: 1584,
            sp_param: `'${Login_User_cp}',''`,
            Login_User_Token,
            Login_User_cp,
          })
        ),
        API.post(
          serialize({
            id: 1584,
            sp_param: `'${Login_User_cp}','${faDate}'`,
            Login_User_Token,
            Login_User_cp,
          })
        ),
      ]);
      let resAllVisitParse = original(resAllVisit.data, false);
      let resVisitFutureParse = original(resVisitFuture.data, false);

      console.log(resVisitFutureParse);
      setAllVisits(resAllVisitParse);
      setVisitFuture(resVisitFutureParse);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchVisit = async (e) => {
    e.preventDefault();

    try {
      const [resVisitOn, resAllVisit] = await Promise.all([
        API.post(
          serialize({
            id: 1585,
            sp_param: `'${values.fday}','${values.tday}','${values.c_week.value}','${values.cs.value}','${values.cp_expert.value}','${Login_User_cp}'`,
            Login_User_Token,
            Login_User_cp,
          })
        ),
        API.post(
          serialize({
            id: 1584,
            sp_param: `'${Login_User_cp}',''`,
            Login_User_Token,
            Login_User_cp,
          })
        ),
      ]);

      let resVisitOnParse = original(resVisitOn.data, false);
      let resAllVisitParse = original(resAllVisit.data, false);
      console.log(resVisitOnParse);
      setVisitOn(resVisitOnParse);
      setValuesVisit({
        fday: values.fday,
        tday: values.tday,
        c_week: values.c_week,
        cs: values.cs,
        cp_expert: values.cp_expert,
        desc: values.desc
      });
      // setAllVisits(resAllVisitParse);
      fetchUpdateData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddVisit = async (vdate) => {
    try {
      const res = await API.post(
        serialize({
          id: 1586,
          sp_param: `'${valuesVisit.fday}','${valuesVisit.tday}','${valuesVisit.c_week.value}','${valuesVisit.cs.value}','${valuesVisit.cp_expert.value}','${vdate}','${valuesVisit.desc}','${Login_User_cp}'`,
          Login_User_Token,
          Login_User_cp,
        })
      );
      let resParse = original(res.data, false);

      if (resParse[0].s_result > "0") {
        fetchUpdateData();
        swal("", `وقت با موفقیت ثبت شد`, "success");
      } else {
        swal("", `${resParse[0].s_message}`, "warning");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveVisit = async (visitId) => {
    try {
      const res = await API.post(
        serialize({
          id: 1587,
          sp_param: `'${visitId}','${Login_User_cp}'`,
          Login_User_Token,
          Login_User_cp,
        })
      );
      let resParse = original(res.data, false);

      console.log(resParse[0]);

      if (resParse[0].s_result > "0") {
        fetchUpdateData();
        swal("", `وقت با موفقیت کنسل شد`, "success");
      } else {
        swal("", `${resParse[0].s_message}`, "warning");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="user-visit">
      <form className="user-visit__form">
        <div className="text-input">
          <label className="text-input__label">{translate("cs")}</label>
          <Select
            options={services}
            value={values.cs}
            isLoading={services.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                cs: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("cp_expert")}</label>
          <Select
            options={experts}
            value={values.cp_expert}
            isLoading={experts.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                cp_expert: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("c_week")}</label>
          <Select
            options={weekApi}
            value={values.c_week}
            isLoading={weekApi.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                c_week: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("fday")}</label>
          <InputMask
            {...dateMask}
            value={values.fday}
            className="text-input__input ltr"
            onChange={(e) => {
              setValues({ ...values, fday: e.target.value });
            }}
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("tday")}</label>
          <InputMask
            {...dateMask}
            value={values.tday}
            className="text-input__input ltr"
            onChange={(e) => {
              setValues({ ...values, tday: e.target.value });
            }}
          />
        </div>

        <div className="text-input w-custom">
          <label className="text-input__label">{translate("description")}</label>
          <textarea
            value={values.desc}
            className="text-input__textarea"
            onChange={(e) => setValues({ ...values, desc: e.target.value })}
            // placeholder={
            //   config.fast_visit_description
            //     ? config.fast_visit_description
            //     : "description"
            // }
          ></textarea>
        </div>

        <div className="button-container" style={{ textAlign: "left" }}>
          <button className="button" onClick={handleSearchVisit}>
            {translate("search")}
          </button>
        </div>
      </form>

      <div className="user-visit__lists">
        <div className="user-visit__lists_1">
          <h3 className="user-visit__lists_title">وقت های قابل رزرو</h3>
          <div className="user-visit__lists_grid">
            <ul className="user-visit__lists_items">
              {!loading &&
                visitOn.map((item) => (
                  <li className="user-visit__lists_item" key={item.S_NUM}>
                    {`${item.sdate} (${item.S_NUM})`}
                    <button
                      className="user-visit__lists_btn"
                      onClick={() => handleAddVisit(item.sdate)}
                    >
                      +
                    </button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <div className="user-visit__lists_2">
          <h3 className="user-visit__lists_title">وقت های آینده</h3>
          <div className="user-visit__lists_grid">
            <ul className="user-visit__lists_items">
              {!loading &&
                visitFuture.map((item) => (
                  <li className="user-visit__lists_item" key={item.id_timeing}>
                    {`${item.sdate} - ${item.stime}`}
                    <button
                      className="user-visit__lists_btn"
                      onClick={() => handleRemoveVisit(item.id_timeing)}
                    >
                      -
                    </button>
                  </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="user-visit__lists_3">
          <h3 className="user-visit__lists_title">کل وقت ها با کد پیگیری</h3>
          <div className="user-visit__lists_grid">
            <ul className="user-visit__lists_items">
              {!loading &&
                allVisits.map((item) => (
                  <li className="user-visit__lists_item" key={item.id_timeing}>
                    {`${item.sdate} - ${item.stime} (${item.id_timeing})`}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
