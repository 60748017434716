const initialState = {
    cp: "0",
    cp_expert: "0",
    cp_technician: "0",
    c_kind: "0",
    cc: "0",
    ccs: "0",
    c_kind_ser: "0",
    cs: "0",
    cl: "0",
    d_num:"0",
    dup_num: "0",
    _all: "0",
    _upper: "0",
    _lower: "0",
    _ur: "0",
    _ul: "0",
    _lr: "0",
    _ll: "0",
    LNUM: "",
    descr: "0",
    lucp: "0"
}

export default (state = initialState, { type, payload }) => {
    switch (type) {

    case "UPDATE_CHRT_VALUES":
        return { ...state, ...payload }

    default:
        return state
    }
}
