import { useState } from "react";
import tooths31 from "../../assets/img/tooths/BB_31.png";
import tooths32 from "../../assets/img/tooths/BB_32.png";
import tooths33 from "../../assets/img/tooths/BB_33.png";
import tooths34 from "../../assets/img/tooths/BB_34.png";
import tooths35 from "../../assets/img/tooths/BB_35.png";
import tooths36 from "../../assets/img/tooths/BB_36.png";
import tooths37 from "../../assets/img/tooths/BB_37.png";
import tooths38 from "../../assets/img/tooths/BB_38.png";

const dentalList = [
	{ image: tooths31, dental: 31 },
	{ image: tooths32, dental: 32 },
	{ image: tooths33, dental: 33 },
	{ image: tooths34, dental: 34 },
	{ image: tooths35, dental: 35 },
	{ image: tooths36, dental: 36 },
	{ image: tooths37, dental: 37 },
	{ image: tooths38, dental: 38 },
];

export default function DentalChartLl({
	chart,
	handleSetDentalList,
	handleRemoveDentalList,
	list
}) {
	// const [list, setList] = useState([]);

	const findDental = (dentalNumber) => {
		let flag = false;
		if (!chart) return false;
		chart.map((item) => {
			if (dentalNumber == item.d_num) {
				flag = true;
			}
		});
		return flag;
	};

	const handleSelectShowNot = (e, checkFlag, id) => {
		// select tooth
		if (!list.find((e) => e === id)) {
			// setList([...list, id]);
			handleSetDentalList(id);
		} else {
			// remove tooth
			// setList(list.filter((item) => item !== id));
			handleRemoveDentalList(id);
		}

		// show notfication
		if (checkFlag) {
			const el = e.target.parentElement.lastChild;
			[...document.querySelectorAll(".dental-chart__charts_images__notif")].map(
				(item) => {
					item.classList.remove("show");
				},
			);
			el.classList.add("show");
			window.onclick = (e) => handleHideNot(e, el);
		}
	};

	// hide notification
	const handleHideNot = (e, el) => {
		if (!e.target.closest(".dental-chart__charts_images__item")) {
			// console.log(e.target);
			el.classList.remove("show");
		}
	};

	return (
		<div className="dental-chart__charts_images dental-chart__charts_ll">
			{dentalList.map((item) => {
				let checkFlag = findDental(item.dental);
				return (
					<div
						key={item.dental}
						className={`dental-chart__charts_images__item  ${
							list.find((e) => e === item.dental) ? "green-filter" : ""
						}`}
						onClick={(e) => handleSelectShowNot(e, checkFlag, item.dental)}
					>
						<img src={item.image} className={checkFlag ? "red-filter" : ""} />
						{checkFlag ? (
							<div className="dental-chart__charts_images__notif">
								{chart
									.filter((item2) => item2.d_num == item.dental)
									.map((item3) => (
										<div>
											<p>
												<span>({item3.id_dental_chart})</span>
											</p>
											{"-"}
											<p>
												<span>{item3.sdate}</span>
											</p>
											{" - "}
											<p>
												<span>{item3.n_technician}</span>{" "}
												<span>({item3.cp_technician})</span>
											</p>
											{" - "}
											<p>
												<span>{item3.n_expert}</span>{" "}
												<span>({item3.cp_expert})</span>
											</p>
											{" - "}
											<p>
												<span>{item3.ns}</span>
											</p>
											{" - "}
											<p>
												<span>{item3.nc}</span> <span>{item3.ncs}</span>
											</p>
										</div>
									))}
							</div>
						) : (
							<></>
						)}
					</div>
				);
			})}
		</div>
	);
}
