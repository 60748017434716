import { useEffect, useState } from "react";
import swal from "sweetalert";
import Select from "react-select";

import API from "../../../api/baseUrl";
import { serialize, original } from "../../../functions/helper";
// import loader from "../../../assets/loader.svg";
import translate from "../../../functions/translate";

const option = [
  { label: 1, value: "1" },
  { label: 2, value: "2" },
  { label: 3, value: "3" },
  { label: 4, value: "4" },
];
export default function UserInfo() {
  const Login_User_Token = localStorage.getItem("user_login_token");
  const Login_User_cp = localStorage.getItem("user_login_cp");

  const [values, setValues] = useState({
    addr1: "",
    addr2: "",
    bank_acc: "",
    bdate: "",
    c_bnk: { label: 1, value: 0 },
    c_edu: { label: 1, value: 0 },
    c_insura: { label: 1, value: 0 },
    c_insura_sub: { label: 1, value: 0 },
    c_intro: { label: 1, value: 0 },
    c_job: { label: 1, value: 0 },
    c_mar: { label: 1, value: 0 },
    c_rel: { label: 1, value: 0 },
    c_rff: { label: 1, value: 0 },
    c_ser_loc: { label: 1, value: 0 },
    c_sex: { label: 1, value: 0 },
    ccity: { label: 1, value: 0 },
    ccountry: { label: 1, value: 0 },
    cg: { label: 1, value: 0 },
    cgs: { label: 1, value: 0 },
    cprovince: { label: 1, value: 0 },
    descr: "",
    edate: "",
    eid: "",
    email: "",
    fname: "",
    fnid: "",
    foreigners: "False",
    insura_num: "",
    legal: "False",
    lname: "",
    mdate: "",
    mobil: "",
    nid: "",
    np: "",
    pocd1: "",
    pocd2: "",
    rnid: "",
    sdate: "",
    tel: "",
    unid: "",
    web: "",
    fax: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await API.post(
        serialize({
          id: 1706,
          sp_param: `'${Login_User_cp}'`,
          Login_User_Token,
          Login_User_cp,
        })
      );
      let resParse = original(res.data, false)[0];

      // console.log(resParse);

      setValues({
        addr1: resParse.addr1,
        addr2: resParse.addr2,
        bank_acc: resParse.bank_acc,
        bdate: resParse.bdate,
        c_bnk: { label: 1, value: 0 },
        c_edu: { label: 1, value: 0 },
        c_insura: { label: 1, value: 0 },
        c_insura_sub: { label: 1, value: 0 },
        c_intro: { label: 1, value: 0 },
        c_job: { label: 1, value: 0 },
        c_mar: { label: 1, value: 0 },
        c_rel: { label: 1, value: 0 },
        c_rff: { label: 1, value: 0 },
        c_ser_loc: { label: 1, value: 0 },
        c_sex: { label: 1, value: 0 },
        ccity: { label: 1, value: 0 },
        ccountry: { label: 1, value: 0 },
        cg: { label: 1, value: 0 },
        cgs: { label: 1, value: 0 },
        cprovince: { label: 1, value: 0 },
        descr: resParse.descr,
        edate: resParse.edate,
        eid: resParse.eid,
        email: resParse.email,
        fname: resParse.fname,
        fnid: resParse.fnid,
        foreigners: resParse.foreigners,
        insura_num: resParse.insura_num,
        legal: resParse.legal,
        lname: resParse.lname,
        mdate: resParse.mdate,
        mobil: resParse.mobil,
        nid: resParse.nid,
        np: resParse.np,
        pocd1: resParse.pocd1,
        pocd2: resParse.pocd2,
        rnid: resParse.rnid,
        sdate: resParse.sdate,
        tel: resParse.tel,
        unid: resParse.unid,
        web: resParse.web,
        fax: resParse.fax,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    const button = e.target;
    button.setAttribute("disabled", "disabled");

    try {
      const res = await API.post(
        serialize({
          id: 1707,
          sp_param: `'${values.np}','${values.nid}','${values.unid}','${values.rnid}','${values.fnid}','${values.eid}','${values.fname}','${values.lname}','${values.father}','${values.mobil}','${values.tel}','${values.fax}','${values.email}','${values.web}','${values.addr1}','${values.pocd1}','${values.addr2}','${values.pocd2}',${values.ccountry.value},'${values.cprovince.value}','${values.ccity.value}','${values.sdate}','${values.edate}','${values.bdate}','${values.mdate}','${values.c_sex.value}','${values.c_edu.value}','${values.c_mar.value}','${values.c_job.value}','${values.c_rel.value}','${values.c_ser_loc.value}','${values.c_rff.value}','${values.c_bnk.value}','${values.c_intro.value}','${values.foreigners}','${values.legal}','${values.cg.value}','${values.cgs.value}','${values.c_insura.value}','${values.c_insura_sub.value}','${values.insura_num}','${values.bank_acc}','${values.descr}','${Login_User_cp}'`,
          Login_User_Token,
          Login_User_cp,
        })
      );
      let resParse = original(res.data, false)[0];
      if (resParse.s_result === "1") {
        swal("", "پروفایل با موفقیت بروز شد", "success", {
          button: "متوجه شدم",
        });
        localStorage.setItem("user_login_np", values.np);
        button.removeAttribute("disabled");
      } else {
        swal("", `${resParse.s_message}`, "warning", {
          button: "متوجه شدم",
        });
        button.removeAttribute("disabled");
      }
      // console.log(resParse);
    } catch (error) {
      console.log(error);
      button.removeAttribute("disabled");
    }
  };

  console.log(values);

  return (
    <div className="user-info">
      <form className="user-info__form">
        <div className="text-input">
          <label className="text-input__label">{translate("np")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.np}
            onChange={(e) =>
              setValues({
                ...values,
                np: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("fname")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.fname}
            onChange={(e) =>
              setValues({
                ...values,
                fname: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("lname")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.lname}
            onChange={(e) =>
              setValues({
                ...values,
                lname: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("nid")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.nid}
            onChange={(e) =>
              setValues({
                ...values,
                nid: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("email")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.email}
            onChange={(e) =>
              setValues({
                ...values,
                email: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("mobil")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.mobil}
            onChange={(e) =>
              setValues({
                ...values,
                mobil: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("father")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.father}
            onChange={(e) =>
              setValues({
                ...values,
                father: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("c_bnk")}</label>
          <Select
            options={option}
            value={values.c_bnk}
            isLoading={option.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                c_bnk: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("bank_acc")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.bank_acc}
            onChange={(e) =>
              setValues({
                ...values,
                bank_acc: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("bdate")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.bdate}
            onChange={(e) =>
              setValues({
                ...values,
                bdate: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("edate")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.edate}
            onChange={(e) =>
              setValues({
                ...values,
                edate: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("eid")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.eid}
            onChange={(e) =>
              setValues({
                ...values,
                eid: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("fax")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.fax}
            onChange={(e) =>
              setValues({
                ...values,
                fax: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("fnid")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.fnid}
            onChange={(e) =>
              setValues({
                ...values,
                fnid: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("insura_num")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.insura_num}
            onChange={(e) =>
              setValues({
                ...values,
                insura_num: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("mdate")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.mdate}
            onChange={(e) =>
              setValues({
                ...values,
                mdate: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("pocd1")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.pocd1}
            onChange={(e) =>
              setValues({
                ...values,
                pocd1: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("pocd2")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.pocd2}
            onChange={(e) =>
              setValues({
                ...values,
                pocd2: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("rnid")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.rnid}
            onChange={(e) =>
              setValues({
                ...values,
                rnid: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("web")}</label>
          <input
            className="text-input__input"
            type="text"
            value={values.web}
            onChange={(e) =>
              setValues({
                ...values,
                web: e.target.value,
              })
            }
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("c_insura")}</label>
          <Select
            options={option}
            value={values.c_insura}
            isLoading={option.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                c_insura: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">
            {translate("c_insura_sub")}
          </label>
          <Select
            options={option}
            value={values.c_insura_sub}
            isLoading={option.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                c_insura_sub: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("c_intro")}</label>
          <Select
            options={option}
            value={values.c_intro}
            isLoading={option.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                c_intro: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("c_mar")}</label>
          <Select
            options={option}
            value={values.c_mar}
            isLoading={option.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                c_mar: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("c_edu")}</label>
          <Select
            options={option}
            value={values.c_edu}
            isLoading={option.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                c_edu: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("c_job")}</label>
          <Select
            options={option}
            value={values.c_job}
            isLoading={option.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                c_job: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("c_sex")}</label>
          <Select
            options={option}
            value={values.c_sex}
            isLoading={option.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                c_sex: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("cg")}</label>
          <Select
            options={option}
            value={values.cg}
            isLoading={option.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                cg: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("cgs")}</label>
          <Select
            options={option}
            value={values.cgs}
            isLoading={option.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                cgs: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("c_rel")}</label>
          <Select
            options={option}
            value={values.c_rel}
            isLoading={option.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                c_rel: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("c_rff")}</label>
          <Select
            options={option}
            value={values.c_rff}
            isLoading={option.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                c_rff: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("c_ser_loc")}</label>
          <Select
            options={option}
            value={values.c_ser_loc}
            isLoading={option.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                c_ser_loc: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("ccountry")}</label>
          <Select
            options={option}
            value={values.ccountry}
            isLoading={option.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                ccountry: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("cprovince")}</label>
          <Select
            options={option}
            value={values.cprovince}
            isLoading={option.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                cprovince: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("ccity")}</label>
          <Select
            options={option}
            value={values.ccity}
            isLoading={option.length === 0 ? true : false}
            onChange={(e) => {
              setValues({
                ...values,
                ccity: { label: e.label, value: e.value },
              });
            }}
            loadingMessage={() => "لطفا صبر کنید..."}
            placeholder=""
          />
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("addr1")}</label>
          <textarea
            className="text-input__textarea"
            value={values.addr1}
            onChange={(e) =>
              setValues({
                ...values,
                addr1: e.target.value,
              })
            }
          ></textarea>
        </div>

        <div className="text-input">
          <label className="text-input__label">{translate("descr")}</label>
          <textarea
            className="text-input__textarea"
            value={values.descr}
            onChange={(e) =>
              setValues({
                ...values,
                descr: e.target.value,
              })
            }
          ></textarea>
        </div>

        <div className="button-container">
          <button className="button" onClick={handleUpdateProfile}>
            ذخیره تغییرات
          </button>
        </div>
      </form>
    </div>
  );
}
