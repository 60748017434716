import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

import API from "../../api/baseUrl";
import { serialize, original } from "../../functions/helper";
import translate from "../../functions/translate";
import loader from "../../assets/loader.svg";

export default function Modal({ showModal, closeModal, data, filter }) {
  const Login_User_Token = localStorage.getItem("user_login_token");
  const Login_User_cp = localStorage.getItem("user_login_cp");

  const [reportList, setReportList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (showModal) fetchReportList();
  }, [showModal]);

  window.addEventListener("click", (e) => {
    if (
      !e.target.closest(".modal__box") &&
      !e.target.closest(".table-report tr td")
    ) {
      closeModal();
    }
  });

  const fetchReportList = async () => {
    try {
      setLoading(true);
      const res = await API.post(
        serialize({
          id: data.c_permission,
          sp_param: `'${Login_User_Token}','${Login_User_cp}','','','','','','${filter.fdate}','${filter.tdate}','${filter.ftime}','${filter.ttime}','${filter.cp}','${filter.cp_expert}','${filter.cs}','${filter.c_pay}','${filter.c_bnk}','${filter.c_insura}','${filter.c_insura_sub}','${filter.ins_cp}','${filter.edi_cp}','${filter.del_cp}','${filter.descr}','${filter.cc}','${filter.ccs}','${filter.cp_technician}','${filter.c_kind}','${filter.c_shift}','${filter.c_branch}','${filter.cp_str}','${filter.cp_expert_str}','${filter.cs_str}','${filter.c_insura_str}','${filter.cc_str}','${filter.cp_technician_str}','${filter.c_shift_str}','${filter.c_branch_str}','${filter.cp_den_lab}','','',''`,
          Login_User_Token,
          Login_User_cp,
        })
      );
      let resParse = original(res.data, false);

      setReportList(resParse);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={`modal ${showModal ? "modal__show" : ""}`}>
      <div className="modal__box">
        <div className="modal__head">
          <h3 className="modal__head_title">
            {showModal && data.report_operation}
          </h3>
          <button className="modal__head_close" onClick={closeModal}>
            <AiOutlineClose />
          </button>
        </div>

        <div className="modal__body">
          <div className="overflow">
            {!loading ? (
              <table className="table" style={{ width: "max-content" }}>
                {reportList[0] && (
                  <thead>
                    <tr>
                      {Object.keys(reportList[0]).map((item, i) => (
                        <th key={i}>{translate(item)}</th>
                      ))}
                    </tr>
                  </thead>
                )}
                <tbody>
                  {reportList.length > 0 ? (
                    reportList.map((item, i) => (
                      <tr key={i}>
                        {Object.entries(item).map(([key, item], childIndex) => (
                          <td key={childIndex} className={"dentis-font"}>{item}</td>
                        ))}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>ایتمی برای نمایش وجود ندارد</td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              <div className="loader-container">
                <img src={loader} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
