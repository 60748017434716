import { AiOutlineClose } from "react-icons/ai";
import { useState, useEffect } from "react";
import swal from "sweetalert";

import { serialize, original } from "../../functions/helper";
import API from "../../api/baseUrl";
import translate from "../../functions/translate";
import config from "../../config.json";
import loader from "../../assets/loader.svg";
import { paymentService } from "../../api/payment";

export default function Modal({ showModal, closeModal, data }) {
  const Login_User_Token = localStorage.getItem("user_login_token");
  const Login_User_cp = localStorage.getItem("user_login_cp");

  const [loading, setLoading] = useState(true);
  const [visits, setVisits] = useState([]);
  const [sdate, setSdate] = useState("");
  // const [desc, setDesc] = useState("");

  useEffect(() => {
    if (showModal) handleSearchVisit();
  }, [showModal]);

  window.addEventListener("click", (e) => {
    if (
      !e.target.closest(".modal__box") &&
      !e.target.closest(".table-report tr td") &&
      !e.target.closest(".fast-visit-page__form_btn") &&
      !e.target.closest(".swal-overlay")
    ) {
      closeModal();
    }
  });

  // console.log(showModal);

  const handleSearchVisit = async (e) => {
    try {
      setVisits([]);
      setLoading(true);

      const resVisit = await API.post(
        serialize({
          id: 1712,
          sp_param: `'${Login_User_Token}','${Login_User_cp}','0','0','0','0','0','${data.fday}','${data.tday}','${data.cs.value}','${data.cp_expert.value}','${data.c_week.value}'`,
          Login_User_Token,
          Login_User_cp,
        })
      );

      let resVisitParse = original(resVisit.data, false);

      console.log(resVisitParse);
      setVisits(resVisitParse);
      setLoading(false);
      // setValuesVisit({
      //   fday: data.fday,
      //   tday: data.tday,
      //   c_week: data.c_week,
      //   cs: data.cs,
      //   cp_expert: data.cp_expert,
      // });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddVisit = async (e) => {
    e.preventDefault();
    try {
      if (!sdate) {
        swal("لطفا نوبت مورد نطر را انتخاب کنید");
        return;
      }

      const res = await API.post(
        serialize({
          id: 1586,
          sp_param: `'${data.fday}','${data.tday}','${data.c_week.value}','${data.cs.value}','${data.cp_expert.value}','${sdate}','${data.desc}','${Login_User_cp}'`,
          Login_User_Token,
          Login_User_cp,
        })
      );
      let resParse = original(res.data, false);

      if (resParse[0].s_result > "0") {
        handleSearchVisit();
        swal("", `وقت با موفقیت ثبت شد`, "success");
      } else {
        swal("", `${resParse[0].s_message}`, "warning");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (item) => {
    paymentService(
      data.initialCode,
      data.phoneNumber,
      item.cost,
      data.desc,
      0,
      0,
      1
    );
  };

  return (
    <div className={`modal fast-visit-modal ${showModal ? "modal__show" : ""}`}>
      <div className="modal__box">
        <div className="modal__head">
          <h3 className="modal__head_title">رزرو نوبت</h3>
          <button className="modal__head_close" onClick={closeModal}>
            <AiOutlineClose />
          </button>
        </div>

        <div className="modal__body">
          <form>
            <div className="user-visit__lists_items">
              {!loading ? (
                visits.length && visits[0].sdate ? (
                  visits.map((item, index) => (
                    <div className="user-visit__lists_item">
                      <div className="user-visit__lists_info">
                        <span className="user-visit__lists_item__num">
                          {item.S_NUM}
                        </span>
                        <span className="user-visit__lists_item__date">
                          {item.sdate}
                        </span>
                        {item.cost ? (
                          <span className="user-visit__lists_item__cost">
                            {Number(item.cost).toLocaleString()} ریال
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                      <button
                        className="button"
                        type="button"
                        onClick={() => handleClick(item)}
                      >
                        ثبت و پرداخت
                      </button>
                    </div>
                    // <label className="user-visit__lists_item" key={index} for={`fastvisitInput-${index}`}>
                    //   {`${item.sdate}  (${item.S_NUM})`}
                    //   <input
                    //     type="radio"
                    //     name="fastvisitInput"
                    //     value={item.S_NUM}
                    //     id={`fastvisitInput-${index}`}
                    //     className="user-visit__lists_btn"
                    //     onClick={() => setSdate(item.sdate)}
                    //   />
                    // </label>
                  ))
                ) : (
                  <div className="mini-loading">
                    <p>محتوایی برای نمایش وجود ندارد</p>
                  </div>
                )
              ) : (
                <div className="mini-loading">
                  <img src={loader} />
                </div>
              )}
            </div>
            {/* <div>
              <textarea className="user-visit__lists_text" placeholder="توضیحات کاربر" onChange={(e) => setDesc(e.target.value)}></textarea>
            </div> */}
            {/* {visits.length ? (
							<button
								onClick={handleAddVisit}
								className="user-visit__lists_btn"
								style={{ width: "100%" }}
							>
								{config.fast_visit_insert_button
									? config.fast_visit_insert_button
									: "submit"}
							</button>
						) : (
							<></>
						)} */}
          </form>
        </div>
      </div>
    </div>
  );
}
