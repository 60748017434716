export default function ChangePassword() {
  return (
    <div className="change-password">
      <form className="change-password__form">
        <div className="text-input">
          <label className="text-input__label">رمز فعلی</label>
          <input className="text-input__input" type="password" />
        </div>

        <div className="text-input">
          <label className="text-input__label">رمز جدید</label>
          <input className="text-input__input" type="password" />
        </div>

        <div className="text-input">
          <label className="text-input__label">تکرار رمز جدید</label>
          <input className="text-input__input" type="password" />
        </div>

        <div className="button-container">
          <button className="button" type="button">
            تغییر رمز عبور
          </button>
        </div>
      </form>
    </div>
  );
}
