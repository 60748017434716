import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DentalStepOne from "./stepOne";
import DentalStepTow from "./stepTow";
import DentalStepThree from "./stepThree";
import DentalStepFhor from "./stepFhor";
import DentalStepFive from "./stepFive";
import { faDate } from "../../../functions/date";
import { useSelector } from "react-redux";
import API from "../../../api/baseUrl";
import { original, serialize } from "../../../functions/helper";
import swal from "sweetalert";
import { Redirect } from "react-router";

const steps = ["جاری", " شخص", " سرویس", " دندان", " نهایی"];

export default function DentalChart() {
	const Login_User_Token = localStorage.getItem("user_login_token");
	const Login_User_cp = localStorage.getItem("user_login_cp");

	const [activeStep, setActiveStep] = React.useState(0);
	const [skipped, setSkipped] = React.useState(new Set());

	const chartValues = useSelector((state) => state.chartValues);

	const isStepOptional = (step) => {
		return step === 1;
	};

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleSkip = () => {
		if (!isStepOptional(activeStep)) {
			// You probably want to guard against something like this,
			// it should never occur unless someone's actively trying to break something.
			throw new Error("You can't skip a step that isn't optional.");
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped((prevSkipped) => {
			const newSkipped = new Set(prevSkipped.values());
			newSkipped.add(activeStep);
			return newSkipped;
		});
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const handleInsert = async () => {
		try {
			const response = await API.post(
				serialize({
					id: 4206,
					sp_param: `'${faDate}','${chartValues.cp}','${Login_User_cp}','${
						chartValues.cp_technician
					}','${chartValues.c_kind}','${chartValues.c_kind}','${
						chartValues.ccs
					}','${chartValues.c_kind_ser}','${chartValues.cs}','${
						chartValues.cl
					}','${chartValues.d_num}','${chartValues.dup_num}','${
						chartValues._all
					}','${chartValues._upper}','${chartValues._lower}','${
						chartValues._ur
					}','${chartValues._ul}','${chartValues._lr}','${
						chartValues._ll
					}','${chartValues.LNUM ? chartValues.LNUM.join(",") : ""}','${
						chartValues.descr
					}','${Login_User_cp}'`,
					Login_User_Token,
					Login_User_cp,
				}),
			);

			let resParse = original(response.data, false);

			if (resParse[0].s_result === "-1117") {
				swal("", `${resParse[0].s_message}`, "warning");
			} else if (resParse[0].s_result === "0") {
				swal("", `${resParse[0].s_param}`, "warning");
			} else {
				swal("", `${resParse[0].s_message}`, "success", {
					button: "متوجه شدم",
				}).then((value) => {
					if (value) {
						window.location.reload();
					} else {
						window.location.reload();
					}
				});
			}
			// console.log(resParse);
			// handleNext();
		} catch (error) {
			console.log(error);
			swal("", `${error}`, "warning");
		}
	};

	return (
		<Fragment>
			{/* <div className="panel-head">
				<h2 className="panel-head__title">چارت دندانی</h2>
			</div> */}
			<div className="dental-chart-page panel-page__paper">
				<Stepper activeStep={activeStep} className="dental-chart-page__stepper">
					{steps.map((label, index) => {
						const stepProps = {};
						const labelProps = {};
						// if (isStepOptional(index)) {
						// 	labelProps.optional = (
						// 		<Typography variant="caption">Optional</Typography>
						// 	);
						// }
						if (isStepSkipped(index)) {
							stepProps.completed = false;
						}
						return (
							<Step key={label} {...stepProps}>
								<StepLabel {...labelProps}>{label}</StepLabel>
							</Step>
						);
					})}
				</Stepper>
				<br />
				<Box
					className="dental-chart-page__btn-container"
					sx={{ display: "flex", flexDirection: "row", pt: 2 }}
				>
					<Button
						color="inherit"
						variant="outlined"
						disabled={activeStep === 0}
						onClick={handleBack}
						sx={{ mr: 1 }}
					>
						بازگشت
					</Button>
					<Box sx={{ flex: "1 1 auto" }} />
					{/* {isStepOptional(activeStep) && (
						<Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
							رد شدن
						</Button>
					)} */}

					{activeStep === steps.length - 1 ? (
						<Button onClick={handleInsert} variant="contained">
							ثبت
						</Button>
					) : (
						<Button onClick={handleNext} variant="contained">
							{activeStep + 1 === 1 ? "ثبت جدید" : "بعدی"}
						</Button>
					)}
				</Box>
				{activeStep === steps.length ? (
					<React.Fragment>
						{/* <p>عملیات با موفقیت انجام شد</p> */}
						{/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
							<Box sx={{ flex: "1 1 auto" }} />
							<Button onClick={handleReset}>Reset</Button>
						</Box> */}
					</React.Fragment>
				) : (
					<React.Fragment>
						{activeStep + 1 === 1 ? (
							<div className="step-panel">
								<DentalStepOne />
							</div>
						) : activeStep + 1 === 2 ? (
							<div className="step-panel">
								<DentalStepTow />
							</div>
						) : activeStep + 1 === 3 ? (
							<div className="step-panel">
								<DentalStepThree />
							</div>
						) : activeStep + 1 === 4 ? (
							<div className="step-panel">
								<DentalStepFhor />
							</div>
						) : activeStep + 1 === 5 ? (
							<div className="step-panel">
								<DentalStepFive />
							</div>
						) : (
							<></>
						)}
					</React.Fragment>
				)}
			</div>
		</Fragment>
	);
}
