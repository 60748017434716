import React from 'react'
import loader from "../../assets/loader.svg";


export default function Loading() {
    return (
        <div className="loader-container">
        <div className="loader-container__box">
            <img src={loader} />
            <span>لطفا صبر کنید</span>
        </div>
    </div>
    )
}
