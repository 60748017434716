import { Fragment, useState } from "react";
import swal from "sweetalert";
import { paymentService } from "../../../api/payment";
import config from "../../../config.json";

import "./style.scss";

export default function Payment() {
	const [data, setData] = useState({
		amount: "",
		email: "",
		phone: "",
		desc: "",
	});

	return (
		<Fragment>
			<div className="panel-head">
				<h2 className="panel-head__title">پرداخت انلاین</h2>
			</div>

			<div className="helplink-page panel-page__paper">
				<div style={{ width: 300 }}>
					<div className="text-input">
						<label className="text-input__label">email</label>
						<input
							type="text"
							className="text-input__input ltr"
							onChange={(e) => {
								setData({ ...data, email: e.target.value });
							}}
						/>
					</div>
					<div className="text-input">
						<label className="text-input__label">phone</label>
						<input
							type="text"
							className="text-input__input ltr"
							onChange={(e) => {
								setData({ ...data, phone: e.target.value });
							}}
						/>
					</div>
					<div className="text-input">
						<label className="text-input__label">amount</label>
						<input
							type="text"
							className="text-input__input ltr"
							onChange={(e) => {
								setData({ ...data, amount: e.target.value });
							}}
						/>
					</div>
					<div className="text-input">
						<label className="text-input__label">description</label>
						<textarea
							type="text"
							className="text-input__input ltr"
							onChange={(e) => {
								setData({ ...data, desc: e.target.value });
							}}
						/>
					</div>
					<button
						className="button"
						style={{ width: "auto" }}
						onClick={() => {
							if (!data.email || !data.phone || !data.amount) {
								swal("", `لطفا فیلد های لازم را پر کنید`, "warning");
								return;
							}
							paymentService(
								data.email,
								data.phone,
								data.amount,
								data.desc,
								"1",
								"10",
							);
						}}
					>
						تست پرداخت انلاین
					</button>
				</div>
			</div>
		</Fragment>
	);
}
