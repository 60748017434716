import { useState } from "react";
import tooths41 from "../../assets/img/tooths/BB_41.png";
import tooths42 from "../../assets/img/tooths/BB_42.png";
import tooths43 from "../../assets/img/tooths/BB_43.png";
import tooths44 from "../../assets/img/tooths/BB_44.png";
import tooths45 from "../../assets/img/tooths/BB_45.png";
import tooths46 from "../../assets/img/tooths/BB_46.png";
import tooths47 from "../../assets/img/tooths/BB_47.png";
import tooths48 from "../../assets/img/tooths/BB_48.png";

const dentalList = [
	{ image: tooths41, dental: 41 },
	{ image: tooths42, dental: 42 },
	{ image: tooths43, dental: 43 },
	{ image: tooths44, dental: 44 },
	{ image: tooths45, dental: 45 },
	{ image: tooths46, dental: 46 },
	{ image: tooths47, dental: 47 },
	{ image: tooths48, dental: 48 },
];

export default function DentalChartLr({
	chart,
	handleSetDentalList,
	handleRemoveDentalList,
	list
}) {

	// const [list, setList] = useState([]);


	const findDental = (dentalNumber) => {
		let flag = false;
		if (!chart) return false;
		chart.map((item) => {
			if (dentalNumber == item.d_num) {
				flag = true;
			}
		});
		return flag;
	};

	const handleSelectShowNot = (e, checkFlag, id) => {
		// select tooth
		if (!list.find((e) => e === id)) {
			// setList([...list, id]);
			handleSetDentalList(id);
		} else {
			// remove tooth
			// setList(list.filter((item) => item !== id));
			handleRemoveDentalList(id);
		}

		// show notfication
		if (checkFlag) {
			const el = e.target.parentElement.lastChild;
			[...document.querySelectorAll(".dental-chart__charts_images__notif")].map(
				(item) => {
					item.classList.remove("show");
				},
			);
			el.classList.add("show");
			window.onclick = (e) => handleHideNot(e, el);
		}
	};

	// hide notification
	const handleHideNot = (e, el) => {
		if (!e.target.closest(".dental-chart__charts_images__item")) {
			// console.log(e.target);
			el.classList.remove("show");
		}
	};

	return (
		<div className="dental-chart__charts_images dental-chart__charts_lr">
			{dentalList.map((item) => {
				let checkFlag = findDental(item.dental);
				return (
					<div
						key={item.dental}
						className={`dental-chart__charts_images__item  ${
							list.find((e) => e === item.dental) ? "green-filter" : ""
						}`}
						onClick={(e) => handleSelectShowNot(e, checkFlag, item.dental)}
					>
						<img src={item.image} className={checkFlag ? "red-filter" : ""} />
						{checkFlag ? (
							<div className="dental-chart__charts_images__notif">
								{chart
									.filter((item2) => item2.d_num == item.dental)
									.map((item3) => (
										<div>
											<p>
												<span>({item3.id_dental_chart})</span>
											</p>
											{"-"}
											<p>
												<span>{item3.sdate}</span>
											</p>
											{" - "}
											<p>
												<span>{item3.n_technician}</span>{" "}
												<span>({item3.cp_technician})</span>
											</p>
											{" - "}
											<p>
												<span>{item3.n_expert}</span>{" "}
												<span>({item3.cp_expert})</span>
											</p>
											{" - "}
											<p>
												<span>{item3.ns}</span>
											</p>
											{" - "}
											<p>
												<span>{item3.nc}</span> <span>{item3.ncs}</span>
											</p>
										</div>
									))}
							</div>
						) : (
							<></>
						)}
					</div>
				);
			})}
		</div>
	);
}
