import { useEffect, useState } from "react";
import swal from "sweetalert";
import Select from "react-select";
import InputMask from "react-input-mask";

import API from "../../../api/baseUrl";
import { serialize, original } from "../../../functions/helper";
// import loader from "../../../assets/loader.svg";
import translate from "../../../functions/translate";
import weekApi from "../../../api/week";
import { faDate } from "../../../functions/date";
import config from "../../../config.json";


const dateMask = {
  mask: "9999/99/99",
  maskChar: "_",
  alwaysShowMask: false,
  formatChars: {
    9: "[0-9]",
    a: "[A-Za-z]",
    "*": "[A-Za-z0-9]",
  },
  permanents: [2, 5],
};

export default function Reservation() {
  const Login_User_Token = localStorage.getItem("user_login_token");
  const Login_User_cp = localStorage.getItem("user_login_cp");

  const [services, setServices] = useState([]);
  const [experts, setExperts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [ruleCheck, setRuleCheck] = useState(false);
  const [values, setValues] = useState({
    fday: faDate,
    c_week: { label: "", value: "" },
    cs: { label: "", value: "" },
    cp_expert: { label: "", value: "" },
    desc: ""
  });


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [resService, resExpert] = await Promise.all([
        API.post(
          serialize({
            id: 1581,
            sp_param: `'${Login_User_cp}'`,
            Login_User_Token,
            Login_User_cp,
          })
        ),
        API.post(
          serialize({
            id: 1582,
            sp_param: `'${Login_User_cp}'`,
            Login_User_Token,
            Login_User_cp,
          })
        ),
      ]);
      let resServiceParse = original(resService.data, false);
      let resExpertParse = original(resExpert.data, false);

      let serviceArr = [];
      let expertArr = [];

      resServiceParse.map((item) => {
        serviceArr.push({ label: `${item.ns} (${item.cs})`, value: item.cs });
      });
      resExpertParse.map((item) => {
        expertArr.push({
          label: `${item.n_expert} (${item.cp})`,
          value: item.cp,
        });
      });
      setServices(serviceArr);
      setExperts(expertArr);
    } catch (error) {
      console.log(error);
    }
  };



  const handleAddVisit = async (e) => {
      e.preventDefault()
    try {
      const res = await API.post(
        serialize({
          id: 1580,
          sp_param: `'${values.fday}','${values.fday}','${values.c_week.value}','${values.cs.value}','${values.cp_expert.value}','${values.desc}','${Login_User_cp}'`,
          Login_User_Token,
          Login_User_cp,
        })
      );
      let resParse = original(res.data, false);

      if (resParse[0].s_result > "0") {
        swal("", `وقت با موفقیت ثبت شد`, "success");
      } else {
        swal("", `${resParse[0].s_message}`, "warning");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="user-visit">
        <div className="overflow-y" style={{maxHeight: 200}}>
            <ul className="user-visit__desc">
                {config.profile_reserve_description_text.split("\n").map((item, index) => (
                    <li key={index}>{item}</li>
                    ))}
            </ul>
        </div>
        
        <div className="text-input__checkbox" style={{margin: "10px"}}>
            <label className="text-input__checkbox_label" for="reserveRuleCheckBox">
                {config.profile_reserve_rule_check_text}   
                <input type="checkbox" className="text-input__checkbox_input" id="reserveRuleCheckBox" onChange={(e) => setRuleCheck(e.target.checked)}/>
                <span className="text-input__checkbox_checkmark"></span>
            </label>
        </div>

                {
                    ruleCheck ? (
                        <form className="user-visit__form">
                        <div className="text-input">
                          <label className="text-input__label">{translate("cs")}</label>
                          <Select
                            options={services}
                            value={values.cs}
                            isLoading={services.length === 0 ? true : false}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                cs: { label: e.label, value: e.value },
                              });
                            }}
                            loadingMessage={() => "لطفا صبر کنید..."}
                            placeholder=""
                          />
                        </div>
                
                        <div className="text-input">
                          <label className="text-input__label">{translate("cp_expert")}</label>
                          <Select
                            options={experts}
                            value={values.cp_expert}
                            isLoading={experts.length === 0 ? true : false}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                cp_expert: { label: e.label, value: e.value },
                              });
                            }}
                            loadingMessage={() => "لطفا صبر کنید..."}
                            placeholder=""
                          />
                        </div>
                
                        <div className="text-input">
                          <label className="text-input__label">{translate("c_week")}</label>
                          <Select
                            options={weekApi}
                            value={values.c_week}
                            isLoading={weekApi.length === 0 ? true : false}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                c_week: { label: e.label, value: e.value },
                              });
                            }}
                            loadingMessage={() => "لطفا صبر کنید..."}
                            placeholder=""
                          />
                        </div>
                
                        <div className="text-input">
                          <label className="text-input__label">{translate("date")}</label>
                          <InputMask
                            {...dateMask}
                            value={values.fday}
                            className="text-input__input ltr"
                            onChange={(e) => {
                              setValues({ ...values, fday: e.target.value });
                            }}
                            readOnly={config.profile_reserve_disabled_date_input ? true : false}
                          />
                        </div>
                
                        <div className="text-input w-custom">
                          <label className="text-input__label">{translate("description")}</label>
                          <textarea
                            value={values.desc}
                            className="text-input__textarea"
                            onChange={(e) => setValues({ ...values, desc: e.target.value })}
                            // placeholder={
                            //   config.fast_visit_description
                            //     ? config.fast_visit_description
                            //     : "description"
                            // }
                          ></textarea>
                        </div>
                
                        <div className="button-container" style={{ textAlign: "left" }}>
                          <button className="button" onClick={handleAddVisit}>
                            {translate("reserve")}
                          </button>
                        </div>
                      </form>
                    ) : <></>
                }
     

      
    </div>
  );
}
