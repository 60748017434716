import React, { useState, useEffect } from "react";
import ChartOne from "../../../../components/charts/ChartOne";
import { original, serialize } from "../../../../functions/helper";
import API from "../../../../api/baseUrl";
import Select from "react-select";
import translate from "../../../../functions/translate";
import Table from "../../../../components/table";
import { useDispatch } from "react-redux";
import { FiSearch } from "react-icons/fi";

export default function DentalStepThree() {
	const Login_User_Token = localStorage.getItem("user_login_token");
	const Login_User_cp = localStorage.getItem("user_login_cp");

	const dispatch = useDispatch();

	const [personTableList, setPersonTableList] = useState([]);
	const [serviceList, setServiceList] = useState([]);
	const [departmentList, setDepartmentList] = useState([]);
	const [searchInput, setSearchInput] = useState("");

	const [dataLoading, setDataLoading] = useState(true);

	useEffect(() => {
		fetchData();
	}, [searchInput]);

	const fetchData = async () => {
		try {
			const [
				resData,
				// resService,
				// resDepartment
			] = await Promise.all([
				API.post(
					serialize({
						id: 4203,
						sp_param: `'${searchInput}','${Login_User_cp}'`,
						Login_User_Token,
						Login_User_cp,
					}),
				),
				// API.post(
				// 	serialize({
				// 		id: 1506,
				// 		Login_User_Token,
				// 		Login_User_cp,
				// 	}),
				// ),
				// API.post(
				// 	serialize({
				// 		id: 1502,
				// 		Login_User_Token,
				// 		Login_User_cp,
				// 		sp_param: ``,
				// 	}),
				// ),
			]);

			let resDataParse = original(resData.data, false);
			// let resServiceParse = original(resService.data, false);
			// let resDepartmentParse = original(resDepartment.data, false);

			// const serviceArr = [];
			// const departmentArr = [];

			// resServiceParse.map((item) => {
			// 	serviceArr.push({ label: `${item.ns} (${item.cs})`, value: item.cs });
			// });

			// resDepartmentParse.map((item) => {
			// 	departmentArr.push({
			// 		label: `${item.nc} (${item.cc})`,
			// 		value: item.cc,
			// 	});
			// });

			setPersonTableList(resDataParse);
			// setServiceList(serviceArr);
			// setDepartmentList(departmentArr)
			setDataLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	const handleSetValue = (values) => {
		dispatch({
			type: "UPDATE_CHRT_VALUES",
			payload: {
				...values,
			},
		});
	};

	return (
		<div className="dantal-step-three">
			<div className="serach-bar">
				<div className="text-input">
					<input
						className="text-input__input"
						placeholder="متن جستجو..."
						style={{ paddingLeft: 40, margin: 0 }}
						onChange={(e) => setSearchInput(e.target.value)}
					/>
					<spna className="text-input__icon">
						<FiSearch />
					</spna>
				</div>
			</div>
			{/* <div className="dental-step-form">
				<div className="text-input">
					<label className="text-input__label">{translate("cs")}</label>
					<Select
						options={serviceList}
						// value={values.service}
						isLoading={dataLoading}
						// onChange={(e) => {
						// 	setValues({
						// 		...values,
						// 		service: { label: e.label, value: e.value },
						// 	});
						// }}
						loadingMessage={() => "لطفا صبر کنید..."}
						placeholder=""
					/>
				</div>

				<div className="text-input">
					<label className="text-input__label">{translate("cc")}</label>
					<Select
						options={departmentList}
						// value={values.department}
						// isLoading={departmentList.length === 0 ? true : false}
						// onChange={(e) => {
						// 	setValues({
						// 		...values,
						// 		department: { label: e.label, value: e.value },
						// 	});
						// }}
						loadingMessage={() => "لطفا صبر کنید..."}
						placeholder=""
					/>
				</div>
			</div> */}
			<Table
				data={personTableList}
				loading={dataLoading}
				deteleItem={false}
				handleClickRow={handleSetValue}
				keySelect="cs"
				disabledHead={true}
			/>
		</div>
	);
}
