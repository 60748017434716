import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import Captcha from "demos-react-captcha";
import Select from "react-select";
import InputMask from "react-input-mask";

import API from "../../api/baseUrl";
import { serialize, original } from "../../functions/helper";
import config from "../../config.json";
import { enDate, faDate } from "../../functions/date";
import FastVisitModal from "../../components/modal/FastVisitModal";
import weekApi from "../../api/week";
import Loading from "../../components/loading";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const dateMask = {
  mask: "9999/99/99",
  maskChar: "_",
  alwaysShowMask: false,
  formatChars: {
    9: "[0-9]",
    a: "[A-Za-z]",
    "*": "[A-Za-z0-9]",
  },
  permanents: [2, 5],
};

export default function FastVisit() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const Login_User_Token = localStorage.getItem("user_login_token");
  const Login_User_cp = localStorage.getItem("user_login_cp");

  const { state } = useLocation();

  const [data, setData] = useState({
    initialCode: state?.nationalCode,
    phoneNumber: state?.phoneNumber,
    fday: faDate,
    tday: faDate,
    desc: "",
    c_week: { label: "", value: "" },
    cs: { label: "", value: "" },
    cp_expert: { label: "", value: "" },
  });

  const [captcha, setCaptcha] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [services, setServices] = useState([]);
  const [experts, setExperts] = useState([]);

  useEffect(() => {
    if (!state?.nationalCode || !state?.phoneNumber) history.push("/");
    else fetchServiceData(Login_User_Token, Login_User_cp);
  }, []);

  useEffect(() => {
    if (!data.fday.includes("_") && !data.tday.includes("_") && data.cs.value)
      fetchExpertData();
  }, [data]);

  console.log(state);

  const fetchExpertData = async () => {
    try {
      const resExpert = await API.post(
        serialize({
          id: 1711,
          sp_param: `'${localStorage.getItem(
            "user_login_token"
          )}','${localStorage.getItem("user_login_cp")}','0','0','0','0','0','${
            data.fday
          }','${data.tday}','${data?.cs?.value}','0'`,
          Login_User_Token: localStorage.getItem("user_login_token"),
          Login_User_cp: localStorage.getItem("user_login_cp"),
        })
      );

      let resExpertParse = original(resExpert.data, false);

      let expertArr = [];

      resExpertParse.map((item) => {
        expertArr.push({
          label: `${item.n_expert} (${item.cp_expert})`,
          value: item.cp_expert,
        });
      });

      setExperts(expertArr);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchServiceData = async (token, cp) => {
    try {
      const resService = await API.post(
        serialize({
          id: 1581,
          sp_param: cp,
          Login_User_Token: token,
          Login_User_cp: cp,
        })
      );

      let resServiceParse = original(resService.data, false);

      let serviceArr = [];
      resServiceParse.map((item) => {
        serviceArr.push({ label: `${item.ns} (${item.cs})`, value: item.cs });
      });

      setServices(serviceArr);
    } catch (error) {
      console.log(error);
    }
  };

  //   const handleLoginOrSignup = async (e) => {
  //     const button = e.target;
  //     button.setAttribute("disabled", "disabled");
  //     e.preventDefault();

  //     try {
  //       const res = await API.post(
  //         serialize({
  //           id: 1705,
  //           sp_param: `'0','0','3','101','machine_nm','machine_ip','machine_id','${data.initialCode}','${data.phoneNumber}'`,
  //           Login_User_Token: 0,
  //           Login_User_cp: 0,
  //         })
  //       );

  //       console.log(original(res.data));

  //       let {
  //         user_login_cp,
  //         user_login_message,
  //         user_login_token,
  //         user_login_np,
  //       } = original(res.data);

  //       if (user_login_cp !== "0") {
  //         localStorage.setItem("user_login_cp", user_login_cp);
  //         localStorage.setItem("user_login_token", user_login_token);
  //         localStorage.setItem("user_login_np", user_login_np);
  //         button.removeAttribute("disabled");
  //         fetchServiceData(user_login_token, user_login_cp);
  //         setShowForm(true);
  //       }
  //       button.removeAttribute("disabled");
  //     } catch (err) {
  //       console.log(err);
  //       swal("", `${err}`, "warning", { button: "متوجه شدم" });
  //       button.removeAttribute("disabled");
  //     }
  //   };

  //   const handleAuthentication = async (e) => {
  //     const button = e.target;
  //     button.setAttribute("disabled", "disabled");
  //     e.preventDefault();

  //     try {
  //       if (!captcha && config.show_fast_visit_captcha === 1) {
  //         swal("", `لطفا متن تصویر را درست وارد کنید`, "warning", {
  //           button: "متوجه شدم",
  //         });
  //         button.removeAttribute("disabled");
  //         return;
  //       }

  //       const res = await API.post(
  //         serialize({
  //           id: 1112,
  //           username: data.initialCode,
  //           password: data.phoneNumber,
  //         })
  //       );

  //       console.log(res);

  //       let {
  //         user_login_cp,
  //         user_login_message,
  //         user_login_token,
  //         user_login_np,
  //       } = original(res.data);

  //       if (user_login_cp !== "0") {
  //         localStorage.setItem("user_login_cp", user_login_cp);
  //         localStorage.setItem("user_login_token", user_login_token);
  //         localStorage.setItem("user_login_np", user_login_np);
  //         button.removeAttribute("disabled");
  //         // fetchData();
  //         setShowForm(true);
  //       } else {
  //         // this.setState({connectingStatus: false})
  //         button.removeAttribute("disabled");
  //         handleSignup();
  //       }
  //     } catch (err) {
  //       console.log(err);
  //       swal("", `${err}`, "warning", { button: "متوجه شدم" });
  //       button.removeAttribute("disabled");
  //     }
  //   };

  //   const handleSignup = async (e) => {
  //     try {
  //       const loginData = await API.post(
  //         serialize({
  //           id: 1112,
  //           username: 0,
  //           password: 0,
  //         })
  //       );
  //       const { user_login_token, user_login_cp } = original(loginData.data);

  //       const res = await API.post(
  //         serialize({
  //           id: config.signup_api_id ? config.signup_api_id : 1702,
  //           sp_param: `'${data.initialCode}','${data.phoneNumber}','${user_login_cp}'`,
  //           Login_User_Token: user_login_token,
  //           Login_User_cp: user_login_cp,
  //         })
  //       );
  //       console.log(original(res.data));
  //       let { s_message, s_result } = original(res.data);
  //       if (s_result > "0") {
  //         // swal("", `${s_message}`, "succsess");
  //         setLoading(true);
  //         setTimeout(() => {
  //           handleLogin();
  //         }, 3000);
  //       } else if (s_result === "0") {
  //         swal("", `${s_message}`, "warning");
  //       } else {
  //         swal("", `${"خطایی رخ داد لطفا دوباره امتحان کنید"}`, "warning");
  //       }
  //     } catch (error) {
  //       // swal("", `${error}`, "warning");
  //       console.log(error);
  //     }
  //   };

  //   const handleLogin = (e) => {
  //     API.post(
  //       serialize({
  //         id: 1112,
  //         username: data.initialCode,
  //         password: data.phoneNumber,
  //       })
  //     )
  //       .then((response) => {
  //         let {
  //           user_login_cp,
  //           user_login_message,
  //           user_login_token,
  //           user_login_np,
  //         } = original(response.data);

  //         if (user_login_cp !== "0") {
  //           localStorage.setItem("user_login_cp", user_login_cp);
  //           localStorage.setItem("user_login_token", user_login_token);
  //           localStorage.setItem("user_login_np", user_login_np);
  //           // fetchData();
  //           setShowForm(true);

  //           swal(
  //             "",
  //             `شما پیشفرض با
  //           نام کاربری: کد ملی
  //           رمزعبور: شماره تلفن
  //           ثبت نام شدید لطفا برای تغییر نام کاربری و رمز عبور اقدام نمایید`,
  //             "success",
  //             {
  //               button: "متوجه شدم",
  //             }
  //           );
  //         } else {
  //           swal("", `${user_login_message}`, "warning");
  //         }
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         // this.setState({connectingStatus: false})
  //         setLoading(true);
  //         console.log(err);
  //         swal("", `${err}`, "warning", { button: "بسیار خب" });
  //       });
  //   };

  // const handleInsertVisit = async () => {
  //   try {

  //     const res = await API.post({
  //       id: '',
  //       sp_param: `'${data.initialCode}','${data.phoneNumber}','${user_login_cp}'`,
  //         Login_User_Token: user_login_token,
  //         Login_User_cp: user_login_cp,

  //     })
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  //   const onChangeCaptcha = (value) => {
  //     if (value) {
  //       setCaptcha(value);
  //     } else {
  //       setCaptcha(false);
  //     }
  //   };

  const handleOpenModal = () => {
    if (
      !data.initialCode ||
      !data.phoneNumber ||
      !data.fday ||
      !data.tday ||
      !data.cs.value ||
      !data.cp_expert.value
    ) {
      swal("همه ی موارد الزامی می باشد", { button: "متوجه شدم" });
    } else {
      setShowModal(true);
    }
  };

  return (
    <div
      className="fast-visit-page"
      style={{
        background: `linear-gradient(45deg,${
          config.auth_back_color_1
            ? config.auth_back_color_1
            : "rgba(86, 0, 110, 1)"
        } 10%,${
          config.auth_back_color_2
            ? config.auth_back_color_2
            : "rgba(33, 150, 243, 1)"
        } 100%)`,
      }}
    >
      <div className="fast-visit-page__box">
        <h1>{config.header ? config.header : "NeedSoft"}</h1>
        <h2 className="fast-visit-page__title">
          {config.header_fast_visit ? config.header_fast_visit : "insert visit"}
        </h2>
        <div className="fast-visit-page__desc">
          {config.fast_visit_description_text.split("\n").map((item, index) => (
            <span key={index}>{item}</span>
          ))}
        </div>
        <form className="fast-visit-page__form">
          <div className="text-input">
            <input
              type="text"
              id="username"
              className="text-input__input ltr"
              value={data.initialCode}
              //   onChange={(e) =>
              //     setData({ ...data, initialCode: e.target.value })
              //   }
              //   disabled={showForm ? true : false}
              readOnly
              placeholder={
                config.fast_visit_input_initial_code
                  ? config.fast_visit_input_initial_code
                  : "initial code"
              }
            />
          </div>

          <div className="text-input">
            <input
              type="text"
              id="text"
              className="text-input__input ltr"
              readOnly
              value={data.phoneNumber}
              //   onChange={(e) =>
              //     setData({ ...data, phoneNumber: e.target.value })
              //   }
              //   disabled={showForm ? true : false}
              placeholder={
                config.register_input_phone
                  ? config.register_input_phone
                  : "phone"
              }
            />
          </div>

          <div className="text-input">
            <InputMask
              {...dateMask}
              value={data.fday}
              className="text-input__input ltr"
              onChange={(e) => setData({ ...data, fday: e.target.value })}
              placeholder={
                config.fast_visit_input_sdate
                  ? config.fast_visit_input_sdate
                  : "start date"
              }
            />
          </div>

          <div className="text-input">
            <InputMask
              {...dateMask}
              value={data.tday}
              className="text-input__input ltr"
              onChange={(e) => setData({ ...data, tday: e.target.value })}
              placeholder={
                config.fast_visit_input_edate
                  ? config.fast_visit_input_edate
                  : "end date"
              }
            />
          </div>

          <div className="text-input">
            <Select
              options={services}
              //   value={options[0]}
              //   isLoading={userLoading}
              onChange={(e) => {
                setData({
                  ...data,
                  cs: { label: e.label, value: e.value },
                });
              }}
              //   onInputChange={(e) => handleUserInputChange(e)}
              loadingMessage={() => "لطفا صبر کنید"}
              // onKeyDown={handleFindUser}
              placeholder={
                config.fast_visit_input_cs
                  ? config.fast_visit_input_cs
                  : "service code"
              }
              // isClearable={true}
            />
          </div>

          <div className="text-input">
            <Select
              options={experts}
              //   value={options[0]}
              //   isLoading={userLoading}
              onChange={(e) => {
                setData({
                  ...data,
                  cp_expert: { label: e.label, value: e.value },
                });
              }}
              //   onInputChange={(e) => handleUserInputChange(e)}
              loadingMessage={() => "لطفا صبر کنید"}
              // onKeyDown={handleFindUser}
              placeholder={
                config.fast_visit_input_expert
                  ? config.fast_visit_input_expert
                  : "expert code"
              }
              // isClearable={true}
            />
          </div>

          <div className="text-input">
            <textarea
              value={data.desc}
              className="text-input__textarea"
              onChange={(e) => setData({ ...data, desc: e.target.value })}
              placeholder={
                config.fast_visit_description
                  ? config.fast_visit_description
                  : "description"
              }
            ></textarea>
          </div>

          {/* <div style={{ width: "100%" }}>
            {config.show_fast_visit_captcha === 1 && (
              <Captcha onChange={onChangeCaptcha} placeholder="کد تصویر" />
            )}
          </div> */}

          <div className="button-container">
            {/* {!showForm ? (
              <button
                className="fast-visit-page__form_btn"
                onClick={handleLoginOrSignup}
              >
                {config.fast_visit_button_text_1
                  ? config.fast_visit_button_text_1
                  : "next"}
              </button>
            ) : ( */}
            <button
              type="button"
              className="fast-visit-page__form_btn"
              onClick={handleOpenModal}
            >
              {config.fast_visit_button_text_2
                ? config.fast_visit_button_text_2
                : "submit"}
            </button>
            {/* )} */}
            <div className="fast-visit-page__form_links">
              <Link to="/">مرحله قبل</Link>
            </div>
          </div>
        </form>
        <div className="fast-visit-page__box_footer">
          <span>{enDate}</span>
        </div>
      </div>

      <FastVisitModal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        data={data}
      />
      {loading && <Loading />}
    </div>
  );
}
