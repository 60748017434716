import React, { Fragment, useEffect, useState } from "react";
import DocumentTable from "../../../../components/documentTable";
import Select from "react-select";
import translate from "../../../../functions/translate";
import API from "../../../../api/baseUrl";
import { serialize, original } from "../../../../functions/helper";
import swal from "sweetalert";
import { useDispatch } from "react-redux";
import { FiSearch } from "react-icons/fi";

export default function DentalStepTow() {
	const Login_User_Token = localStorage.getItem("user_login_token");
	const Login_User_cp = localStorage.getItem("user_login_cp");

	const [personTableList, setPersonTableList] = useState([]);
	const [dataLoading, setDataLoading] = useState(true);
	const [searchInput, setSearchInput] = useState("");

	const dispatch = useDispatch();

	useEffect(() => {
		fetchData();
	}, [searchInput]);

	const fetchData = async () => {
		try {
			const response = await API.post(
				serialize({
					id: 4232,
					sp_param: `'${searchInput}','${Login_User_cp}'`,
					Login_User_Token,
					Login_User_cp,
				}),
			);

			let resParse = original(response.data, false);

			console.log(resParse);

			setPersonTableList(resParse);
			setDataLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	const handleSetValue = (values) => {
		dispatch({
			type: "UPDATE_DOCUMENTS_VALUES",
			payload: {
				cp: values.cp
			},
		});
	};

	return (
		<Fragment>
			<div className="serach-bar">
				<div className="text-input">
					<input
						className="text-input__input"
						placeholder="متن جستجو..."
						style={{ paddingLeft: 40, margin: 0 }}
						onChange={(e) => setSearchInput(e.target.value)}
					/>
					<spna className="text-input__icon">
						<FiSearch />
					</spna>
				</div>
			</div>
			<DocumentTable
				data={personTableList}
				loading={dataLoading}
				deteleItem={false}
				handleClickRow={handleSetValue}
				keySelect="cp"
				disabledHead={true}
			/>
		</Fragment>
	);
}
