import API from "./baseUrl";
import { serialize, original } from "../functions/helper";
import swal from "sweetalert";


export const paymentService = async (
	email,
	phone,
	amount,
	desc,
	shift,
	branch,
	gateway = 1,
	) => {
		const Login_User_Token = localStorage.getItem("user_login_token");
		const Login_User_cp = localStorage.getItem("user_login_cp");
		try {
		const response = await API.post(
			serialize({
				id: 1741,
				sp_param: `'${Login_User_Token}','${Login_User_cp}','${shift}','${branch}','','','','${gateway}','${amount}','${desc}','${phone}','${email}'`,
				Login_User_Token,
				Login_User_cp,
			}),
		);
		let resParse = original(response.data, false);

		console.log(resParse);

		// window.location.href = resParse[0]?.link_StartPay;

		if (resParse[0].link_StartPay) {
			window.open(resParse[0]?.link_StartPay, "_blank");
		} else {
			swal("", `${resParse[0].s_message}`, "error");

		}
	} catch (error) {
		console.log(error);
		// swal("", `${error}`, "warning");
	}
};
