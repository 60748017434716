import React, { Fragment } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import DocumentsStepOne from "./stepOne";
import DentalStepTow from "./stepTow";
import DentalStepThree from "./stepThree";
import DentalStepFhor from "./stepFhor";
import { useSelector } from "react-redux";
import API from "../../../api/baseUrl";
import { original, serialize } from "../../../functions/helper";
import swal from "sweetalert";

const steps = ["شخص", "کارها", " مراجعه", " نمایش"];

export default function Documents() {
	const Login_User_Token = localStorage.getItem("user_login_token");
	const Login_User_cp = localStorage.getItem("user_login_cp");

	const [activeStep, setActiveStep] = React.useState(0);
	const [skipped, setSkipped] = React.useState(new Set());

	const isStepOptional = (step) => {
		return step === 1;
	};

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};


	return (
		<Fragment>
			{/* <div className="panel-head">
				<h2 className="panel-head__title">چارت دندانی</h2>
			</div> */}
			<div className="dental-chart-page panel-page__paper">
				<Stepper activeStep={activeStep} className="dental-chart-page__stepper">
					{steps.map((label, index) => {
						const stepProps = {};
						const labelProps = {};
						// if (isStepOptional(index)) {
						// 	labelProps.optional = (
						// 		<Typography variant="caption">Optional</Typography>
						// 	);
						// }
						if (isStepSkipped(index)) {
							stepProps.completed = false;
						}
						return (
							<Step key={label} {...stepProps}>
								<StepLabel {...labelProps}>{label}</StepLabel>
							</Step>
						);
					})}
				</Stepper>
				<br />
				<Box
					className="dental-chart-page__btn-container"
					sx={{ display: "flex", flexDirection: "row", pt: 2 }}
				>
					<Button
						color="inherit"
						variant="outlined"
						disabled={activeStep === 0}
						onClick={handleBack}
						sx={{ mr: 1 }}
					>
						بازگشت
					</Button>
					<Box sx={{ flex: "1 1 auto" }} />
					{/* {isStepOptional(activeStep) && (
						<Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
							رد شدن
						</Button>
					)} */}

					{activeStep === steps.length - 1 ? (
                         <></>
						// <Button onClick={handleInsert} variant="contained">
						// 	ثبت
						// </Button>
					) : (
						<Button onClick={handleNext} variant="contained">
							بعدی
						</Button>
					)}
				</Box>
				{activeStep === steps.length ? (
					<React.Fragment>
						{/* <p>عملیات با موفقیت انجام شد</p> */}
						{/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
							<Box sx={{ flex: "1 1 auto" }} />
							<Button onClick={handleReset}>Reset</Button>
						</Box> */}
					</React.Fragment>
				) : (
					<React.Fragment>
						{activeStep + 1 === 1 ? (
							<div className="step-panel">
								<DocumentsStepOne />
							</div>
						) : activeStep + 1 === 2 ? (
							<div className="step-panel">
								<DentalStepTow />
							</div>
						) : activeStep + 1 === 3 ? (
							<div className="step-panel">
								<DentalStepThree />
							</div>
						) : activeStep + 1 === 4 ? (
							<div className="step-panel">
								<DentalStepFhor />
							</div>
						) : (
							<></>
						)}
					</React.Fragment>
				)}
			</div>
		</Fragment>
	);
}
