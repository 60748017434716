import { useState, useEffect, Fragment } from "react";
import Select from "react-select";
import InputMask from "react-input-mask";
import swal from "sweetalert";

import API from "../../../api/baseUrl";
import { serialize, original } from "../../../functions/helper";
import DepositList from "./DepositList";
import translate from '../../../functions/translate';

const dateMask = {
  mask: "9999/99/99",
  maskChar: "_",
  alwaysShowMask: false,
  formatChars: {
    9: "[0-9]",
    a: "[A-Za-z]",
    "*": "[A-Za-z0-9]",
  },
  permanents: [2, 5], // permanents is an array of indexes of the non-editable characters in the mask
};

const timeMask = {
  mask: "99:99",
  maskChar: "_",
  alwaysShowMask: false,
  formatChars: {
    9: "[0-9]",
    // a: "[A-Za-z]",
    "*": "[A-Za-z0-9]",
  },
};

const cpayList = [
  { label: "کیف پول", value: 1 },
  { label: "پوز", value: 2 },
  { label: "چک", value: 3 },
  { label: "نقد", value: 4 },
];

const cbnkList = [
  { label: "حساب ندارد", value: 1 },
  { label: "حساب ملت", value: 2 },
  { label: "حساب ملی", value: 3 },
  { label: "حساب نمونه", value: 4 },
];

export default function Deposit() {
  const Login_User_Token = localStorage.getItem("user_login_token");
  const Login_User_cp = localStorage.getItem("user_login_cp");

  const [data, setData] = useState({
    cp: "",
    payment: "",
    sdate: "",
    stime: "",
    c_pay: { label: "", value: 0 },
    c_bnk: { label: "", value: 0 },
    descr: "",
  });

  const [dataResult, setDataResult] = useState(null);
  const [personList, setPersonList] = useState([]);
  const [userLoading, setUserLoading] = useState(false);

  const handleInsertDeposit = async (e) => {
    const button = e.target;
    e.preventDefault();

    try {
      e.preventDefault();
      button.setAttribute("disabled", "disabled");

      const res = await API.post(
        serialize({
          id: 2621,
          sp_param: `'${data.cp}','${data.payment}','${data.sdate}','${data.stime}','${data.c_pay.value}','${data.c_bnk.value}','${data.descr}','','','${Login_User_cp}'`,
          Login_User_Token,
          Login_User_cp,
        })
      );
      let resParse = original(res.data, false);

      console.log(resParse);

      setDataResult(resParse[0]);

      if (resParse[0].s_result === "0" || resParse[0].s_result < 0) {
        swal("", `${resParse[0].s_param}`, "warning");
        button.removeAttribute("disabled");

        return;
      }
      if (resParse[0].s_result > 0) {
        clearInputValue();
        button.removeAttribute("disabled");

        swal("", "واریز شما با موفقیت ثبت شد", "success");
      }
    } catch (error) {
      console.log(error);
      button.removeAttribute("disabled");
    }
  };

  const handleUserInputChange = (e) => {
    // console.log(e);
    if (e === "") {
      setPersonList([]);
      setUserLoading(false);
      return;
    }

    setUserLoading(true);
    API.post(
      serialize({
        id: 2467,
        sp_param: `'${e}','20','${Login_User_cp}'`,
        Login_User_Token,
        Login_User_cp,
      })
    )
      .then((response) => {
        let res = original(response.data, false);
        let personArr = [];
        res.map((item) => {
          personArr.push({
            label: `${item.np} (${item.cp})`,
            value: item.cp,
          });
        });
        // console.log(res);
        setPersonList(personArr);
        setUserLoading(false);
      })
      .catch((err) => {
        // this.setState({connectingStatus: false})
        swal("", `${err}`, "warning");
        setUserLoading(false);
      });
  };

  const clearInputValue = () => {
    setData({
      cp: "",
      payment: "",
      sdate: "",
      stime: "",
      c_pay: { label: "", value: 0 },
      c_bnk: { label: "", value: 0 },
      descr: "",
    });
    [...document.querySelectorAll(".text-input__input")].map((item) => {
      item.value = "";
    });
  };

  return (
    <Fragment>
      <div className="panel-head">
        <h2 className="panel-head__title">واریزی</h2>
      </div>
      <div className="deposit-page panel-page__paper">
        <form className="visit-page__form">
          <div className="text-input">
            <label className="text-input__label">{translate('cp')}</label>
            <Select
              options={personList}
              isLoading={userLoading}
              onChange={(e) => {
                if (e) setData({ ...data, cp: e.value });
                else setData({ ...data, cp: "" });
              }}
              onInputChange={(e) => handleUserInputChange(e)}
              loadingMessage={() => "لطفا صبر کنید"}
              // onKeyDown={handleFindUser}
              placeholder=""
              isClearable={true}
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('payment')}</label>
            <input
              type="text"
              className="text-input__input ltr"
              onChange={(e) => {
                setData({ ...data, payment: e.target.value });
              }}
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('sdate')}</label>
            <InputMask
              {...dateMask}
              value={data.sdate}
              className="text-input__input ltr"
              onChange={(e) => {
                setData({ ...data, sdate: e.target.value });
              }}
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('stime')}</label>
            <InputMask
              {...timeMask}
              className="text-input__input ltr"
              value={data.stime}
              onChange={(e) => {
                setData({ ...data, stime: e.target.value });
              }}
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('c_pay')}</label>
            <Select
              options={cpayList}
              value={data.c_pay}
              isLoading={cpayList.length === 0 ? true : false}
              onChange={(e) => {
                setData({ ...data, c_pay: e });
              }}
              loadingMessage={() => "لطفا صبر کنید..."}
              placeholder=""
            />
          </div>

          <div className="text-input">
            <label className="text-input__label">{translate('c_bnk')}</label>
            <Select
              options={cbnkList}
              value={data.c_bnk}
              isLoading={cbnkList.length === 0 ? true : false}
              onChange={(e) => {
                setData({ ...data, c_bnk: e });
              }}
              loadingMessage={() => "لطفا صبر کنید..."}
              placeholder=""
            />
          </div>

          <div className="text-input" style={{ width: "100%" }}>
            <label className="text-input__label">{translate('descr')}</label>
            <textarea
              className="text-input__textarea"
              maxLength="1000"
              onChange={(e) => {
                setData({ ...data, descr: e.target.value });
              }}
            ></textarea>
          </div>

          <div className="button-container">
            <button
              className="button"
              onClick={handleInsertDeposit}
              type="button"
            >
              ثبت
            </button>
          </div>
        </form>

        <DepositList update={dataResult} />
      </div>
    </Fragment>
  );
}
